import React from "react";

const Footer = () => {
  const date = new Date();
  const fullYear = date?.getFullYear();
  return (
    <div className="w-full text-[#666666] text-xs font-semibold text-center underline p-2">
      Copyright @{fullYear} Project Management System. All Right Reserved.
    </div>
  );
};

export default Footer;
