import React from "react";

export const PriorityIcon = ({ value }) => {
  const priorityColors = {
    High: "#F44336",
    Medium: "#2780C1",
    Low: "#919EA7",
  };

  const color = priorityColors[value] || "#919EA7"; // Default to Low if no match

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.7207 1.83203V20.1654"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default function ProjectHeading({ label = "", value = "" }) {
  return (
    <div className="flex flex-col gap-1">
      <h3 className="text-[#757575]">{label}</h3>
      <span
        className={`text-[#1F1F1F] font-semibold text-sm flex justify-start items-center gap-2  `}
      >
        <span>{value}</span>
      </span>
    </div>
  );
}
