import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConnector } from "../../networking/ApiConnector";
import { officeLocationEndPoints } from "../../networking/Endpoints";

// Define an async thunk
export const fetchOfficeLocation = createAsyncThunk(
  "fetchOfficeLocation",
  async ({ token, currentPage, itemsPerPage }) => {
    let queryParams = {};
    if (currentPage && itemsPerPage) {
      queryParams.page_number = currentPage;
      queryParams.page_size = itemsPerPage;
    }
    const response = await apiConnector(
      "GET",
      officeLocationEndPoints?.OFFICE_LOCATION_API,
      null,
      {
        Authorization: `Bearer ${token}`,
      },
      queryParams
    );

    return response?.data?.data;
  }
);

// Create a slice
const officeLocationSlice = createSlice({
  name: "officeLocation",
  initialState: {
    officeLocationData: null,
    officeLocationLoading: false,
    officeLocationError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOfficeLocation.pending, (state) => {
        state.officeLocationLoading = true;
        state.officeLocationData = null;
        state.officeLocationError = null;
      })
      .addCase(fetchOfficeLocation.fulfilled, (state, action) => {
        state.officeLocationData = action.payload;
        state.officeLocationLoading = false;
        state.officeLocationError = null;
      })
      .addCase(fetchOfficeLocation.rejected, (state, action) => {
        state.officeLocationError = action.error.message;
        state.officeLocationLoading = false;
        state.officeLocationData = null;
      });
  },
});

export default officeLocationSlice.reducer;
