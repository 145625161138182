import { createContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const CustomAlertContext = createContext("");

export const CustomAlertContextProvider = ({ children }) => {
  const [type, setType] = useState("success");

  const setToastNotification = (message = "", type = "success") => {
    if (["success", "error", "info", "warning"].includes(type)) {
      toast[type](message);
    } else {
      toast.error("Invalid toast type");
    }
  };

  return (
    <CustomAlertContext.Provider value={{ setToastNotification }}>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {children}
    </CustomAlertContext.Provider>
  );
};
