import React, { useContext, useEffect, useState } from "react";
import { getAllTeamTask } from "../../redux/slice/taskSlice";
import { CommonTaskTable } from "../task";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CustomAlertContext } from "../../context";

const ProjectTeamTask = ({ project }) => {
  const { token, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [tasks, setTasks] = useState([]);
  const [tasksTableData, setTasksTableData] = useState([]);
  const { setToastNotification } = useContext(CustomAlertContext);

  const [taskCount, setTaskCount] = useState(0);
  const projectId = project?._id;
  const fetchTeamTasks = async (
    project,
    milestone,
    assign_to,
    assign_by,
    status,
    priority
  ) => {
    try {
      const response = await dispatch(
        getAllTeamTask({
          token,
          project,
          milestone,
          assign_to,
          assign_by,
          status,
          priority,
          currentPage,
          itemsPerPage,
          projectId,
        })
      ).unwrap();
      if (response?.data) {
        setTasks(response?.data?.tasks);
        setTasksTableData(response?.data?.tasks);
        setTaskCount(response?.data?.task_count);
      }
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchTeamTasks();
    }
  }, [projectId, currentPage, itemsPerPage]);
  return (
    <>
      <CommonTaskTable
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        setCurrentPage={setCurrentPage}
        setItemsPerPage={setItemsPerPage}
        tasks={tasks}
        setTasks={setTasks}
        tasksTableData={tasksTableData}
        setTasksTableData={setTasksTableData}
        taskCount={taskCount}
        defaultProject={project?._id}
        fetchAllTasks={fetchTeamTasks}
      />
    </>
  );
};

export default ProjectTeamTask;
