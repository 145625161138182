import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import CommonTooltip from "./CommonTooltip";
import { CheckStatus } from "../../helper/CheckStatus";

const CustomStatusButton = ({ selectedStatus = "", isDropdown = false }) => {
  return (
    <CommonTooltip tooltip={"Status"}>
      <div
        className={`w-[131px] relative p-1.5 rounded-md flex justify-between items-center gap-1 text-custom-white bg-${CheckStatus(
          selectedStatus
        )}`}
      >
        <div className="flex justify-center items-center gap-2 text-sm">
          <div className={`w-2 h-2 rounded-full bg-custom-white`}></div>
          <span className="font-medium">{selectedStatus ?? "--"}</span>
        </div>
        {isDropdown && (
          <span>
            <MdKeyboardArrowDown size={20} className="text-custom-white" />
          </span>
        )}
      </div>
    </CommonTooltip>
  );
};

export default CustomStatusButton;
