import React, { useContext, useEffect, useState } from "react";
import { getAllPersonalTask } from "../../redux/slice/taskSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import CommonTaskTable from "./CommonTaskTable";
import { CustomAlertContext } from "../../context";

const PersonalTask = () => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [tasks, setTasks] = useState([]);
  const [tasksTableData, setTasksTableData] = useState([]);
  const [taskCount, setTaskCount] = useState(0);
  const { setToastNotification } = useContext(CustomAlertContext);

  const fetchPersonalTask = async (
    project,
    milestone,
    assign_to,
    assign_by,
    status,
    priority
  ) => {
    try {
      const response = await dispatch(
        getAllPersonalTask({
          token,
          project,
          milestone,
          assign_to,
          assign_by,
          status,
          priority,
          currentPage,
          itemsPerPage,
        })
      ).unwrap();
      if (response?.data) {
        setTasks(response?.data?.tasks);
        setTasksTableData(response?.data?.tasks);
        setTaskCount(response?.data?.task_count);
      }
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };
  useEffect(() => {
    fetchPersonalTask();
  }, [currentPage, itemsPerPage]);

  return (
    <>
      <CommonTaskTable
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        setCurrentPage={setCurrentPage}
        setItemsPerPage={setItemsPerPage}
        tasks={tasks}
        setTasks={setTasks}
        tasksTableData={tasksTableData}
        setTasksTableData={setTasksTableData}
        taskCount={taskCount}
        fetchAllTasks={fetchPersonalTask}
      />
    </>
  );
};

export default PersonalTask;
