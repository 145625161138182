import { Status } from "../constant/Constant";

export const CheckStatus = (status) => {
  switch (status) {
    case Status?.in_progress:
      return "custom-status-in-progress";
    case Status?.completed:
      return "custom-status-completed";
    case Status?.cancelled:
      return "custom-status-cancelled";
    case Status?.not_started:
      return "custom-status-not-started";
    case Status?.on_hold:
      return "custom-status-on-hold";
    default:
      return "";
  }
};
