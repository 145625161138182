import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Roles } from "../../constant/Constant";
import { fetchAllEmployees } from "../../redux/slice/employeeSlice";
import { toast } from "react-toastify";

const Dashboard = () => {
  const { token, user } = useSelector((state) => state.auth);

  return (
    <div className="w-full">
      <section className="w-full bg-custom-white border border-slate-200 p-3 rounded-md">
        <h2 className="text-sm md:text-lg">Hello👋 {user?.user_name}</h2>
      </section>
    </div>
  );
};

export default Dashboard;
