import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Error = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  return (
    <div className="w-full h-full flex flex-col items-center gap-5">
      <svg
        width="509"
        height="547"
        viewBox="0 0 509 547"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M496.593 279.014L491.492 277.532L491.468 277.524L438.867 262.277V36.4897C438.867 25.283 439.256 13.9953 438.867 2.79661V2.31889C438.867 -0.758106 434.082 -0.766204 434.082 2.31889V245.588C434.082 250.665 434.001 255.766 433.952 260.859L292.37 219.83L191.177 190.502L188.934 210.664L202.449 199.595L199.469 229.903L198.311 241.644L185.314 373.963L184.221 385.105L180.764 420.337L182.399 386.061L183.047 372.449L186.124 307.937L187.501 279.143L189.412 239.069L189.979 227.158L190.392 218.478L185.412 225.838L178.578 235.936L175.411 240.616L175.719 235.102L176.383 223.215L178.416 186.801L138.771 175.311V25.866C138.771 18.1492 139.111 10.3514 138.771 2.64275V2.31078C138.771 -0.766212 133.985 -0.774309 133.985 2.31078V169.57C133.985 171.019 133.977 172.469 133.953 173.918L88.13 160.638C79.5387 158.153 70.5507 163.1 68.0567 171.691L63.0525 188.963L62.6719 190.267L62.4289 191.101L59.4411 201.409L0.856692 403.551C-2.45513 414.976 4.12795 426.928 15.5533 430.24L321.002 518.76L334.201 522.08L338.598 418.547L324.921 426.183L329.561 347.736L319.69 353.105L334.039 305.241L322.233 347.728L334.039 338.4L331.585 379.81L330.5 398.029L329.715 411.244L329.561 413.851L341.286 407.997L342.687 418.531L344.808 434.507L347.27 453.074L348.849 465.002L357.391 529.295L413.741 545.627C425.166 548.939 437.118 542.364 440.43 530.931L502.407 317.071L507.622 299.071C510.132 290.488 505.185 281.5 496.593 279.014Z"
          fill="#FBDACF"
        />
        <path
          d="M190.392 218.484L185.42 225.845L189.979 227.165L190.392 218.484Z"
          fill="#ED6935"
        />
        <path
          d="M138.779 175.315V193.121C138.779 196.206 133.994 196.198 133.994 193.121V192.789C133.888 190.303 133.848 187.801 133.84 185.299C133.84 181.51 133.913 177.704 133.961 173.914L88.1382 160.635C79.5469 158.149 70.5589 163.096 68.0649 171.688L63.0607 188.959L62.6802 190.263L176.375 223.211L178.408 186.797L138.779 175.315Z"
          fill="#ED6935"
        />
        <path
          d="M463.119 288.964C462.52 291.036 460.35 292.227 458.277 291.628C456.204 291.028 455.013 288.858 455.613 286.785C456.212 284.712 458.382 283.522 460.447 284.121C462.528 284.729 463.718 286.899 463.119 288.964ZM476.974 292.98C476.374 295.053 474.204 296.243 472.131 295.644C470.058 295.045 468.868 292.875 469.467 290.802C470.066 288.729 472.237 287.538 474.301 288.138C476.382 288.745 477.581 290.907 476.974 292.98ZM490.836 296.996C490.237 299.069 488.067 300.259 485.994 299.66C483.921 299.061 482.731 296.891 483.33 294.818C483.929 292.745 486.099 291.555 488.164 292.154C490.245 292.761 491.435 294.923 490.836 296.996ZM433.952 260.866L292.362 219.828L191.177 190.508L188.934 210.67L202.433 199.601L199.453 229.901L502.262 317.661L502.432 317.086L507.638 299.069C510.132 290.486 505.185 281.498 496.585 278.996L491.484 277.53L438.851 262.275V279.757C438.851 282.826 434.066 282.826 434.066 279.757V279.271C433.993 276.834 433.944 274.38 433.912 271.927C433.896 268.25 433.912 264.558 433.952 260.866Z"
          fill="#ED6935"
        />
        <path
          d="M474.309 288.143C472.236 287.544 470.074 288.734 469.475 290.807C468.876 292.88 470.066 295.042 472.139 295.65C474.212 296.249 476.382 295.058 476.981 292.986C477.581 290.904 476.382 288.743 474.309 288.143Z"
          fill="#96D5F4"
        />
        <path
          d="M133.993 193.129V192.797C133.888 190.311 133.848 187.809 133.84 185.306C131.337 186.124 129.273 188.124 128.479 190.837C127.216 195.201 129.726 199.76 134.091 201.023C138.455 202.287 143.014 199.776 144.277 195.412C145.532 191.08 143.071 186.561 138.771 185.258V193.129C138.779 196.214 133.993 196.206 133.993 193.129Z"
          fill="#96D5F4"
        />
        <path
          d="M434.082 279.761V279.284C434.001 276.838 433.952 274.393 433.928 271.939C431.426 272.757 429.361 274.757 428.575 277.47C427.312 281.834 429.822 286.393 434.187 287.656C438.551 288.919 443.11 286.409 444.373 282.045C445.628 277.713 443.167 273.194 438.867 271.891V279.761C438.867 282.838 434.082 282.838 434.082 279.761Z"
          fill="#96D5F4"
        />
        <path
          d="M502.269 317.669L499.03 328.811L198.303 241.659L199.46 229.91L502.269 317.669Z"
          fill="#F7B7A6"
        />
        <path
          d="M189.979 227.172L189.404 239.083L178.586 235.933L185.412 225.836L189.979 227.172Z"
          fill="#F7B7A6"
        />
        <path
          d="M176.391 223.221L175.719 235.116L59.4573 201.423L62.437 191.115L62.6962 190.281L176.391 223.221Z"
          fill="#F7B7A6"
        />
        <path
          d="M179.76 278.964C178.658 277.215 177.063 276.025 174.95 275.418C173.039 274.867 171.193 274.851 169.419 275.369C167.646 275.895 165.84 276.859 164.01 278.276L88.2109 340.512C85.6521 342.626 83.7897 344.359 82.6237 345.703C81.4576 347.047 80.6641 348.44 80.2511 349.873C79.5223 352.383 79.7895 354.472 81.0608 356.14C82.3321 357.808 84.2755 359.015 86.9071 359.776L98.1868 363.039L136.787 374.23L131.224 393.429C130.422 396.182 130.536 398.481 131.556 400.336C132.576 402.19 134.398 403.494 137.03 404.255C139.394 404.943 141.443 404.903 143.184 404.149C143.378 404.06 143.572 403.971 143.759 403.866C145.613 402.846 146.933 400.967 147.734 398.214L153.297 379.015L164.585 382.287L165.492 382.554C165.508 382.562 165.524 382.562 165.541 382.57C168.148 383.315 170.286 383.291 171.937 382.481C173.597 381.671 174.78 380.06 175.468 377.671C176.124 375.404 175.97 373.477 174.998 371.898C174.027 370.319 172.229 369.153 169.597 368.383L157.395 364.845L180.221 286.09C181.055 283.223 180.917 280.883 179.824 279.07L179.76 278.964ZM153.443 316.755L140.892 360.059L111.296 351.484L100.009 348.213L158.229 300.244L153.443 316.755Z"
          fill="#0B465E"
        />
        <path
          d="M266.766 301.952C264.992 301.223 263.122 300.575 261.17 300.008C251.486 297.199 242.611 297.288 234.546 300.267C226.489 303.247 219.364 309.029 213.185 317.604C207.007 326.187 201.849 337.588 197.728 351.823C191.663 372.755 190.804 389.913 195.161 403.298C199.517 416.683 208.983 425.485 223.582 429.712C225.534 430.279 227.445 430.724 229.323 431.056C236.903 432.384 243.907 431.801 250.344 429.299C258.377 426.181 265.503 420.278 271.721 411.574C277.932 402.877 283.074 391.525 287.131 377.532C293.268 356.366 294.151 339.216 289.786 326.09C285.997 314.721 278.321 306.673 266.766 301.952ZM270.79 372.812C265.729 390.278 259.672 402.456 252.611 409.355C247.024 414.821 240.644 417.25 233.477 416.651C231.599 416.489 229.664 416.124 227.68 415.55C218.109 412.772 212.189 406.586 209.914 396.982C207.639 387.379 209.032 373.848 214.092 356.382C219.153 338.924 225.186 326.827 232.19 320.106C239.194 313.385 247.478 311.409 257.049 314.187C259.049 314.77 260.887 315.49 262.571 316.349C269.041 319.661 273.179 325.045 274.976 332.511C277.252 341.912 275.851 355.346 270.79 372.812Z"
          fill="#0B465E"
        />
        <path
          d="M395.036 341.359C393.935 339.61 392.339 338.419 390.226 337.812C388.315 337.262 386.469 337.245 384.695 337.764C382.922 338.29 381.116 339.254 379.286 340.671L331.601 379.829L330.516 398.048L373.497 362.639L368.711 379.149L356.16 422.454L342.694 418.551L344.816 434.527L352.055 436.624L347.286 453.094L348.865 465.022C349.796 465.71 350.938 466.244 352.298 466.641C354.662 467.329 356.711 467.289 358.452 466.536C358.646 466.455 358.841 466.358 359.027 466.252C360.873 465.232 362.201 463.354 362.995 460.601L368.557 441.402L380.752 444.932C383.384 445.693 385.53 445.669 387.19 444.851C388.849 444.041 390.032 442.438 390.72 440.041C391.384 437.774 391.222 435.847 390.258 434.268C389.287 432.689 387.489 431.523 384.857 430.754L372.655 427.215L395.481 348.46C396.315 345.594 396.178 343.254 395.084 341.44L395.036 341.359Z"
          fill="#0B465E"
        />
        <path
          d="M297.908 408.084C296.742 409.429 295.949 410.821 295.527 412.255C294.799 414.765 295.066 416.854 296.337 418.522C297.6 420.19 299.552 421.397 302.184 422.166L316.054 426.182L316.143 426.206L317.601 411.267L315.285 410.595L317.876 408.465L319.714 389.582L303.495 402.902C300.937 405.016 299.074 406.74 297.908 408.084Z"
          fill="#0B465E"
        />
        <path
          d="M488.172 292.155C486.099 291.556 483.937 292.746 483.338 294.819C482.738 296.892 483.929 299.054 486.002 299.661C488.075 300.261 490.245 299.07 490.844 296.997C491.435 294.916 490.245 292.754 488.172 292.155Z"
          fill="#0B465E"
        />
        <path
          d="M133.993 193.125C133.993 196.202 138.779 196.21 138.779 193.125V25.866C138.779 18.1492 139.119 10.3514 138.779 2.64275V2.31078C138.779 -0.766212 133.993 -0.774309 133.993 2.31078V169.57C133.993 171.019 133.985 172.469 133.961 173.918C133.912 177.708 133.84 181.505 133.84 185.303C133.84 187.805 133.88 190.307 133.993 192.793V193.125Z"
          fill="#0B465E"
        />
        <path
          d="M438.867 2.3225C438.867 -0.754493 434.081 -0.76259 434.081 2.3225V245.591C434.081 250.668 434 255.77 433.952 260.863C433.911 264.556 433.895 268.248 433.928 271.94C433.952 274.394 434 276.839 434.081 279.285V279.762C434.081 282.839 438.867 282.847 438.867 279.762V36.4933C438.867 25.2866 439.256 13.9989 438.867 2.80022V2.3225Z"
          fill="#0B465E"
        />
      </svg>
      <div className="">
        <h2 className="text-lg md:text-4xl text-center font-semibold text-custom-black">
          Oops! We can't seem to find the resource you're looking for.
        </h2>
        <div className="text-sm text-[#646464] text-center md:mt-4">
          Please check that the Web site address is spelled correctly. Or go to
          our home page, and use the menus to navigate to a specific section.
        </div>
      </div>
      <div className="flex justify-center items-center gap-5 md:mt-5">
        <button
          onClick={() => (token ? navigate("/dashboard") : navigate("/login"))}
          className="border px-2 py-1 rounded-md border-custom-blue text-custom-blue hover:bg-custom-blue hover:text-custom-white font-semibold text-sm"
        >
          {token ? "Go to Dashboard" : "Go to Login"}
        </button>
      </div>
    </div>
  );
};
export default Error;
