import React from "react";

const PaginationText = ({
  currentPage,
  itemsPerPage,
  dataCount,
  dataLength,
}) => {
  return (
    <div>
      Showing {dataCount === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1} to{" "}
      {(currentPage - 1) * itemsPerPage + dataLength} from {dataCount} entries
    </div>
  );
};

export default PaginationText;
