import React from "react";
import ProjectHeading from "./Heading";
import {
  dateFormatter,
  getDateDifferenceStatus,
} from "../../helper/formatDate";
import {
  CustomAssignees,
  CustomButton,
  CustomPriorityButton,
  CustomStatusButton,
} from "../common";
import ProjectNotes from "./ProjectNotes";
import { useNavigate } from "react-router-dom";
import { Roles } from "../../constant/Constant";
import { useSelector } from "react-redux";

const ProjectDetails = ({ project, fetchProjectDetails }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="w-full h-full p-4 flex flex-col lg:flex-row justify-center gap-4">
      <div className="w-full lg:w-2/3 md:space-y-3 flex flex-col">
        <div className="relative font-semibold bg-gradient-custom-light-blue rounded-lg p-2 border">
          <h1 className="w-[88%] text-sm md:text-base text-justify">
            {project?.project_name}
          </h1>
          {(user?.roleId === Roles?.admin ||
            (user?.roleId === Roles?.project_manager &&
              user?._id === project?.project_manager?._id)) && (
            <CustomButton
              title={"Edit Project"}
              buttonType={"button"}
              onClick={() => {
                navigate("/project/create", {
                  state: {
                    _id: project?._id ?? "",
                    project_name: project?.project_name ?? "",
                    project_manager: project?.project_manager ?? "",
                    project_start_date:
                      project?.project_start_date?.split("T")[0] ?? "",
                    project_deadline:
                      project?.project_deadline?.split("T")[0] ?? "",
                    department: project?.department ?? "",
                    members:
                      project?.members?.map((member) => ({
                        name: member?.user_name,
                        value: member._id,
                      })) ?? [],
                    projectStatus: project?.projectStatus ?? "",
                    priority: project?.priority ?? "",
                    project_description: project?.project_description ?? "",
                  },
                });
              }}
              classname="absolute right-0 top-1 underline text-[#2E6790] px-5 py-1 rounded-md"
            />
          )}
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <ProjectHeading label="Project ID" value={project?.project_id} />
          <ProjectHeading
            label="Project Manager"
            value={project?.project_manager?.user_name}
          />
          <ProjectHeading
            label="Department"
            value={project?.department?.department_name}
          />
          <ProjectHeading
            label="Start Date"
            value={
              project?.project_start_date
                ? dateFormatter(project?.project_start_date, {
                    format: "MMM DD, YYYY",
                    includeTimeForToday: false,
                  })
                : "--"
            }
          />
          <ProjectHeading
            label="Deadline(Tentative)"
            value={
              project?.project_deadline ? (
                <>
                  {dateFormatter(project?.project_deadline, {
                    format: "MMM DD, YYYY",
                    includeTimeForToday: false,
                  })}{" "}
                  (
                  {getDateDifferenceStatus(
                    project?.project_deadline,
                    project?.projectStatus === "Completed"
                      ? project?.completed_on
                      : null
                  )}
                  )
                </>
              ) : (
                "--"
              )
            }
          />
          <ProjectHeading
            label="Status"
            value={
              <CustomStatusButton selectedStatus={project?.projectStatus} />
            }
          />
          <ProjectHeading
            label="Priority"
            value={<CustomPriorityButton priority={project?.priority} />}
          />
          <ProjectHeading
            label="Assignees"
            value={<CustomAssignees assignees={project?.members} />}
          />
        </div>
        <div className="w-full overflow-auto">
          <ProjectHeading
            label="Description"
            value={project?.project_description}
          />
        </div>
      </div>
      <div className="w-full lg:w-1/3 h-auto bg-[#FEF8DC] rounded-lg flex-shrink-0">
        <ProjectNotes
          project={project}
          getProjectDetails={fetchProjectDetails}
        />
      </div>
    </div>
  );
};

export default ProjectDetails;
