import React from "react";

const CommonProgressBar = ({ progress = "0" }) => {
  return (
    <div className="flex items-center">
      <div className="w-full h-[7px] bg-[#E8F0E4] rounded-full overflow-hidden border relative">
        <div
          className={`h-full bg-[#47BA11] rounded-full`}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <span className="ml-3 text-gray-700">{`${progress}%`}</span>
    </div>
  );
};

export default CommonProgressBar;
