import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConnector } from "../../networking/ApiConnector";
import { companyEndPoints } from "../../networking/Endpoints";
import { toast } from "react-toastify";

// Define an async thunk
export const fetchCompanyDetails = createAsyncThunk(
  "fetchCompanyDetails",
  async ({ token, companyId }) => {
    let company_details_response;
    try {
      company_details_response = await apiConnector(
        "GET",
        `${companyEndPoints?.COMPANY_API}/${companyId}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
    } catch (error) {
      toast.error(error?.message);
    }
    return company_details_response?.data;
  }
);

// Create a slice
const companySlice = createSlice({
  name: "company",
  initialState: {
    companyData: [],
    companyLoading: false,
    companyError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyDetails.pending, (state) => {
        state.companyLoading = true;
        state.companyData = null;
        state.companyError = null;
      })
      .addCase(fetchCompanyDetails.fulfilled, (state, action) => {
        state.companyData = action.payload;
        state.companyLoading = false;
        state.companyError = null;
      })
      .addCase(fetchCompanyDetails.rejected, (state, action) => {
        state.companyError = action.error.message;
        state.companyLoading = false;
        state.companyData = null;
      });
  },
});

export default companySlice.reducer;
