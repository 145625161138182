const ProjectJason = [
  {
    id: 1,
    name: "PMS",
    websiteUrl: "https://pms.testinguat.com/",
    image: "https://pms.testinguat.com/assets/logo.png",
    description:
      "Our Project Management System is designed to streamline project workflows and enhance team collaboration. With a structured role-based approach, the Admin manages user roles and permissions, ensuring efficient task delegation. The Project Manager is empowered to create projects, assign tasks, and monitor progress. Employees play a critical role in executing tasks and updating their statuses to ensure project milestones are met on time. This system is crafted to boost productivity and maintain transparency in every stage of the project lifecycle.",
  },
  {
    id: 2,
    name: "Edualay ",
    websiteUrl: "https://edualay.com/",
    image: "https://edualay.com/assets/images/logo/logo1.png",
    description:
      "Welcome to Edualay! We are a dynamic platform dedicated to connecting education, internships, and job opportunities. Our mission is to empower individuals by providing access to valuable resources, hands-on experiences, and career pathways. At Edualay, we offer a comprehensive range of teaching resources, internship programs, and job listings tailored to your needs. Whether you’re a student seeking to enhance your skills, an intern looking for real-world experience, or a job seeker ready to advance your career, Edualay is here to support you. Our vision is to create an inclusive community where learning and career development thrive. We partner with top organizations and institutions to ensure you have access to high-quality opportunities.Join us on Edualay and take the next step in your professional journey",
  },
  {
    id: 3,
    name: "HRMS ",
    websiteUrl: "https://hrms.testinguat.com/",
    image: "https://hrms.testinguat.com/HRMS_LOGO.svg",
    description:
      "Our HRMS solution is designed to streamline workforce management by integrating comprehensive modules tailored for Employees, HR, Accounts, and Administrators. With features such as Employee Profiles, Attendance Tracking, Payroll Management, and Asset Management, our platform ensures seamless operations across all levels. Advanced sub-modules for fund management, document generation, and offboarding processes enhance efficiency and transparency in every workflow. Our intuitive interface empowers employees while enabling HR and Accounts to handle complex tasks effortlessly. Focused on optimizing business processes, our system simplifies HR operations, fostering productivity and growth.",
  },
  {
    id: 4,
    name: "CRM ",
    websiteUrl: "https://crm.testinguat.com/",
    image:
      "https://crm.testinguat.com/CRM-master/src/main/resources/static/images/crmLogo.png",
    description:
      "CRM stands for customer relationship management. As its name suggests, CRM software is a system for managing customer relationships. For many businesses, their most valuable and important asset is their customers. In the past, companies kept their contact information scattered across business cards, email records, and spreadsheets. But as businesses expand and change, having a central database for customer information becomes essential.Your prospects and customers will feel the pain when your team isn’t on the same page. From their perspective, they have a relationship with one company, not a collection of different people and departments. Everyone on your team needs context about every customer’s needs, wants, and current state so they can pick up the conversation where it left off.Having one central place to organize all lead and customer details makes it easy for your entire team to gain insight into your business and customer relationships",
  },
  {
    id: 5,
    name: "VMS",
    websiteUrl: "https://vms.testinguat.com/",
    image:
      "https://vms.testinguat.com/static/media/Logo.f5ec8dcc262ac2f7978f.png",
    description:
      "The Village Management System (VMS) is an innovative platform crafted to empower farmers by simplifying agricultural operations and boosting productivity. VMS provides efficient tools for managing farming activities, land records, and crop schedules while offering real-time insights for informed decision-making. With features such as village resource tracking, water and soil management, and access to localized farming guides, VMS supports sustainable agricultural practices. The platform fosters collaboration among farmers, enabling the exchange of best practices and insights into market trends and government schemes. Its intuitive design makes it an indispensable tool for enhancing efficiency, reducing costs, and achieving long-term agricultural success",
  },
  {
    id: 6,
    name: "Cashfuse",
    websiteUrl: "https://cashfuse.testinguat.com/",
    image:
      "https://cashfuse.testinguat.com/static/media/LOGO.992e8458c279aca2aa36a5c00839d57f.svg",
    description:
      "Discovery and savings for local shopping. Food, fashion, beauty, pharmacy, and more. Welcome to CashFuse, a revolutionary payment and referral platform designed to enhance your shopping and transaction experience. At CashFuse, we aim to bridge the gap between merchants and consumers by offering an innovative and secure way to make payments, earn rewards, and redeem exclusive cashback offers. Whether you're paying through a QR code or using theCashFuse ATM card, our platform provides a seamless, user-friendly experience for both users and merchants. Our journey began with a simple yet powerful vision: to empower consumers with a rewarding payment experience while providing merchants with a new avenue to increase customer engagement and sales. With CashFuse, every transaction becomes an opportunity to earn. Users can collect cashback in the form of CashFuse Points after making payments at participating merchants, which can later be redeemed for exclusive discounts or converted into real money",
  },
  {
    id: 7,
    name: "ParkGo",
    websiteUrl: "https://cashfuse.testinguat.com/",
    image: "https://parkgoapp.com/assets/ParkGO%201.png",
    description:
      "Whether you're parking, passing through toll booths, shopping at malls, or refueling your vehicle, our all-in-one FASTag has you covered. Save time, fuel, and effort with smart parking solutions from ParkGO Never miss out on your insurance and Pollution Under Control Certificate (PUCC) renewal again! With ParkGO. With ParkGO, finding information regarding RTO services, traffic rules, and more is easier than ever",
  },
  {
    id: 8,
    name: "GameBiz",
    websiteUrl: "https://gamebiz.network/",
    image: "https://gamebiz.network/images/logo.svg",
    description:
      "Gamebiz is a safe and secure compliance, regulatory project management for merchants and it onboards gaming companies for safe transactions. It deals with bank and GST reconciliation for gaming merchants with payment refund and chargeback management.",
  },
  {
    id: 9,
    name: "franchisebx",
    websiteUrl: "https://franchisebx.com/",
    image: "https://franchisebx.com/img/FranchiseBX%20logo.png",
    description:
      "FranchiseBx is a comprehensive platform designed to provide innovative solutions for franchises and startups, with a particular focus on the French market. The platform supports businesses in managing operations, expanding their networks, and streamlining processes. It also offers tailored resources to help startups establish and grow efficiently in a competitive environment. FranchiseBx bridges the gap between entrepreneurial aspirations and scalable franchise models.",
  },
  {
    id: 10,
    name: "Intelligence Network",
    websiteUrl: "https://singhtek.in/index.html",
    image: "https://singhtek.in/assets/img/logo/Singhtek.in%20logo.svg",
    description:
      "Intelligent Governance refers to the integration of advanced technologies (such as IN, data analytics, and machine learning) into the processes of governance to improve efficiency, transparency, decision-making, and responsiveness. It involves using data-driven tools to support public policy, public service delivery, and governmental decision-making.",
  },
];

export default ProjectJason;
