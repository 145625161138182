export const Roles = {
  super_admin: 1,
  admin: 2,
  project_manager: 3,
  employee: 4,
};

export const Status = {
  in_progress: "In Progress",
  completed: "Completed",
  on_hold: "On Hold",
  cancelled: "Cancelled",
  not_started: "Not Started",
};

export const statusOptions = [
  {
    name: (
      <div className="text-[#B1A000] font-semibold flex justify-start items-center gap-2">
        <p className="w-2 h-2 rounded-full bg-[#B1A000]"></p>
        <span>In Progress</span>
      </div>
    ),
    value: "In Progress",
  },
  {
    name: (
      <div className="text-[#47BA11] font-semibold flex justify-start items-center gap-2">
        <p className="w-2 h-2 rounded-full bg-[#47BA11]"></p>
        <span>Completed</span>
      </div>
    ),
    value: "Completed",
  },
  {
    name: (
      <div className="text-[#3085C3] font-semibold flex justify-start items-center gap-2">
        <p className="w-2 h-2 rounded-full bg-[#3085C3]"></p>
        <span>On Hold</span>
      </div>
    ),
    value: "On Hold",
  },
  {
    name: (
      <div className="text-[#FF0000] font-semibold flex justify-start items-center gap-2">
        <p className="w-2 h-2 rounded-full bg-[#FF0000]"></p>
        <span>Cancelled</span>
      </div>
    ),
    value: "Cancelled",
  },
  {
    name: (
      <div className="text-[#909090] font-semibold flex justify-start items-center gap-2">
        <p className="w-2 h-2 rounded-full bg-[#909090]"></p>
        <span>Not Started</span>
      </div>
    ),
    value: "Not Started",
  },
];

export const priorityOptions = [
  {
    name: (
      <div className="text-[#858585] font-semibold flex justify-start items-center gap-2">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.7207 1.83203V20.1654"
            stroke="#F44336"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
            fill="#F44336"
          />
          <path
            d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
            stroke="#F44336"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span>High</span>
      </div>
    ),
    value: "High",
  },
  {
    name: (
      <div className="text-[#858585] font-semibold flex justify-start items-center gap-2">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.7207 1.83203V20.1654"
            stroke="#2780C1"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
            fill="#2780C1"
          />
          <path
            d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
            stroke="#2780C1"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span>Medium</span>
      </div>
    ),
    value: "Medium",
  },
  // {
  //   name: (
  //     <div className="text-[#909090] font-semibold flex justify-start items-center gap-2">
  //       <svg
  //         width="22"
  //         height="22"
  //         viewBox="0 0 22 22"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <path
  //           d="M4.7207 1.83203V20.1654"
  //           stroke="#E7D327"
  //           stroke-width="1.5"
  //           stroke-miterlimit="10"
  //           stroke-linecap="round"
  //           stroke-linejoin="round"
  //         />
  //         <path
  //           d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
  //           fill="#E7D327"
  //         />
  //         <path
  //           d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
  //           stroke="#E7D327"
  //           stroke-width="1.5"
  //           stroke-miterlimit="10"
  //           stroke-linecap="round"
  //           stroke-linejoin="round"
  //         />
  //       </svg>

  //       <span>Normal</span>
  //     </div>
  //   ),
  //   value: "Normal",
  // },
  {
    name: (
      <div className="text-[#909090] font-semibold flex justify-start items-center gap-2">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.7207 1.83203V20.1654"
            stroke="#919EA7"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
            fill="#919EA7"
          />
          <path
            d="M4.7207 3.66797H14.9874C17.4624 3.66797 18.0124 5.04297 16.2707 6.78464L15.1707 7.88464C14.4374 8.61797 14.4374 9.80964 15.1707 10.4513L16.2707 11.5513C18.0124 13.293 17.3707 14.668 14.9874 14.668H4.7207"
            stroke="#919EA7"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span>Low</span>
      </div>
    ),
    value: "Low",
  },
];

export const chartColors = {
  not_started: "#B0C4DE",
  in_progress: "#FAB111",
  on_hold: "#5175F4",
  completed: "#2BB92B",
  cancelled: "#FF0B0B",
};

export const filterStatusOptions = [
  {
    name: "All",
    value: "",
  },
  {
    name: "In Progress",
    value: "In Progress",
  },
  {
    name: "Completed",
    value: "Completed",
  },
  {
    name: "On Hold",
    value: "On Hold",
  },
  {
    name: "Cancelled",
    value: "Cancelled",
  },
  {
    name: "Not Started",
    value: "Not Started",
  },
];
export const filterPriorityOptions = [
  {
    name: "All",
    value: "",
  },
  {
    name: "High",
    value: "High",
  },
  {
    name: "Medium",
    value: "Medium",
  },
  {
    name: "Low",
    value: "Low",
  },
];
