import React, { useContext, useEffect, useState } from "react";
import {
  CustomButton,
  NoDataFound,
  Pagination,
  PaginationText,
  TableHeader,
} from "../common";
import { MdOutlineEdit, MdOutlineFilterAlt } from "react-icons/md";
import { IoMdSearch } from "react-icons/io";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { apiConnector } from "../../networking/ApiConnector";
import { companyEndPoints } from "../../networking/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/slice/authSlice";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { GrView } from "react-icons/gr";
import { CustomAlertContext } from "../../context";
const Company = () => {
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    { setToastNotification } = useContext(CustomAlertContext),
    { token, loading } = useSelector((state) => state.auth),
    [companies, setCompanies] = useState([]),
    [tableData, setTableData] = useState([]),
    [searchString, setSearchString] = useState(""),
    [companyCount, setCompanyCount] = useState(0),
    [itemsPerPage, setItemsPerPage] = useState(100),
    [currentPage, setCurrentPage] = useState(1);

  const TableHeads = [
    "S.No",
    "Company's Name",
    "Company's Email",
    "Company's Contact",
    "Company's Address",
    "Status",
    "Actions",
  ];

  const getAllCompanies = async () => {
    dispatch(setLoading(true));
    try {
      const companiesResponse = await apiConnector(
        "GET",
        companyEndPoints?.COMPANY_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        },
        {
          page_size: itemsPerPage,
          page_number: currentPage,
        }
      );
      setCompanies(companiesResponse?.data?.data?.companies);
      setTableData(companiesResponse?.data?.data?.companies);
      setCompanyCount(companiesResponse?.data?.data?.company_count);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getAllCompanies();
  }, [itemsPerPage, currentPage]);

  const filteredCompanies = (e) => {
    const val = e?.target?.value;
    setSearchString(e?.target?.value);
    if (val?.length > 0) {
      const filterCompanies = companies?.filter(
        (item) =>
          item?.company_name.toLowerCase().includes(val.toLowerCase()) ||
          item?.company_email.toLowerCase() === val.toLowerCase() ||
          item?.company_contact_no.toString() === val.toString() ||
          item?.company_address.toLowerCase() === val.toLowerCase()
      );
      setTableData(filterCompanies);
    } else {
      setTableData(companies);
    }
  };

  const pageCount = Math.ceil(companyCount / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
  };
  return (
    <div className="w-full">
      <section className="flex sm:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-t-md">
        <h1 className="font-bold text-lg">Company List</h1>
        <div className="flex sm:flex-row flex-col justify-center items-center gap-4">
          <div className="relative">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              type="text"
              placeholder="Search"
              onChange={(e) => filteredCompanies(e)}
              value={searchString}
              className="border px-5 py-2 outline-none rounded-md text-sm font-semibold"
            />
          </div>
          <CustomButton
            title={
              <div className="flex justify-center items-center font-semibold">
                <MdOutlineFilterAlt size={20} />
                <span>Filters</span>
              </div>
            }
            buttonType="submit"
            classname="bg-custom-white text-slate-400 p-2 rounded-md border text-sm font-semibold"
          />
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-2 ">
                <FaPlusCircle />
                <span>Add Company</span>
              </div>
            }
            buttonType="button"
            onClick={() => navigate("/company/create")}
            classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
          />
        </div>
      </section>
      <section className="w-full">
        <table className="w-full border rounded-lg bg-custom-white overflow-auto">
          <thead>
            <TableHeader TableHeads={TableHeads} />
          </thead>
          {loading ? (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm p-3"
                >
                  <Skeleton
                    count={5}
                    width={"100%"}
                    height={"22px"}
                    className="my-2"
                  />
                </td>
              </tr>
            </tbody>
          ) : tableData && tableData?.length > 0 ? (
            <tbody>
              {tableData?.map((item, index) => {
                return (
                  <tr key={index} className="border-b">
                    <td className="p-3 text-sm w-5 text-center font-medium">
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </td>
                    <td className="p-3 text-sm truncate max-w-40">
                      {item?.company_name}
                    </td>
                    <td className="p-3 text-sm">{item?.company_email}</td>
                    <td className="p-3 text-sm">
                      {item?.company_country_code +
                        " " +
                        item?.company_contact_no}
                    </td>
                    <td className="p-3 text-sm truncate max-w-52">
                      {item?.company_address}
                    </td>
                    <td>
                      <div className="flex items-center text-sm text-start gap-2">
                        <div
                          className={`w-3 h-3  rounded-full ${
                            item?.isActive ? "bg-green-500" : "bg-red-500"
                          }`}
                        />
                        <span>{item?.isActive ? "Active " : "Inactive"}</span>
                      </div>
                    </td>
                    <td className="p-3 text-sm flex justify-start items-center gap-2 cursor-pointer">
                      <div
                        onClick={() => {
                          navigate("/company-view", {
                            state: { company_id: item?._id },
                          });
                        }}
                        className="p-1 border border-gray-400 text-gray-400 hover:text-custom-white hover:bg-green-500  hover:border-custom-white rounded-md cursor-pointer"
                      >
                        <GrView size={16} />
                      </div>
                      <div
                        onClick={() => {
                          navigate("/company/create", {
                            state: { companyId: item?._id },
                          });
                        }}
                        className="p-1 border border-custom-blue text-custom-blue hover:text-custom-white hover:bg-custom-blue  hover:border-custom-white rounded-md cursor-pointer"
                      >
                        <MdOutlineEdit size={16} />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm p-4"
                >
                  <NoDataFound />
                </td>
              </tr>
            </tbody>
          )}
          <tfoot className="relative">
            <tr>
              <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                <PaginationText
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  dataCount={companyCount}
                  dataLength={companies?.length}
                />
              </td>
              <td className="absolute right-2 top-1.5">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  pageCount={pageCount}
                  name={"pageSize"}
                  onChange={(e) => setItemsPerPage(e?.target?.value)}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
    </div>
  );
};

export default Company;
