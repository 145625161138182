import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CommonProgressBar,
  CommonTooltip,
  CustomAssignees,
  CustomButton,
  CustomModal,
  CustomPriorityButton,
  CustomStatusButton,
  CustomStatusDropdown,
} from "../common";
import { Roles, Status } from "../../constant/Constant";
import { CiEdit } from "react-icons/ci";
import { dateFormatter } from "../../helper/formatDate";
import { RiDeleteBin6Line, RiDeleteBinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/slice/authSlice";
import { projectEndPoints } from "../../networking/Endpoints";
import { toast } from "react-toastify";
import { apiConnector } from "../../networking/ApiConnector";
import useOnClickOutside from "../../helper/onClickOutside";
import { MdCalendarMonth } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IoSwapHorizontal } from "react-icons/io5";
import { FaRegFileLines } from "react-icons/fa6";
import { setActiveTabInProject } from "../../redux/slice/projectSlice";
import { CustomAlertContext } from "../../context";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ProjectCard = ({
  _id = "",
  project_name = "",
  project_manager = {},
  department = {},
  project_id = "",
  project_description = "",
  start_date = "",
  end_date = "",
  status = "",
  priority = "",
  progress,
  total_task,
  completed_task,
  assignees = [],
  onClick = () => {},
  fetchAllProjects,
}) => {
  const { token, user } = useSelector((state) => state.auth),
    navigate = useNavigate(),
    dispatch = useDispatch(),
    { setToastNotification } = useContext(CustomAlertContext),
    statusRef = useRef(),
    [deleteProject, setDeleteProject] = useState(false),
    [statusModal, setStatusModal] = useState(false),
    [openStatus, setOpenStatus] = useState(false),
    [selectedStatus, setSelectedStatus] = useState(status);

  useOnClickOutside(statusRef, () => setOpenStatus(false));

  const updateProjectStatusHandler = async (projectId) => {
    dispatch(setLoading(true));
    try {
      const project_response = await apiConnector(
        "PUT",
        `${projectEndPoints?.PROJECT_API}/${projectId}`,
        {
          projectStatus: selectedStatus,
        },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      toast.success(project_response?.data?.message);
      if (fetchAllProjects) {
        fetchAllProjects();
      }
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  const handleStatusChange = async (newStatus) => {
    setSelectedStatus(newStatus);
    setOpenStatus(false);
    setStatusModal(true);
  };

  const deleteProjectHandler = async (projectId) => {
    dispatch(setLoading(true));
    try {
      const project_response = await apiConnector(
        "DELETE",
        `${projectEndPoints?.PROJECT_API}/${projectId}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      toast.success(project_response?.data?.message);
      if (fetchAllProjects) {
        fetchAllProjects();
      }
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <div
      onClick={onClick}
      className="border border-[#E8F0F6] bg-custom-white p-4 rounded-lg shadow-[2px_3px_10px_6px_#257CBB0F] space-y-3 cursor-pointer hover:bg-gray-100"
    >
      <div className="flex justify-between items-center gap-2">
        <div className="flex justify-start items-center gap-2">
          <div
            ref={statusRef}
            onClick={(event) => {
              event.stopPropagation();
              if (user?.roleId !== Roles?.employee) {
                setOpenStatus(!openStatus);
              }
            }}
            className={`relative flex justify-between items-center gap-1`}
          >
            <CustomStatusButton
              selectedStatus={
                selectedStatus === status ? selectedStatus : status
              }
              isDropdown={true}
            />
            {openStatus && (
              <CustomStatusDropdown
                statusRef={statusRef}
                handleStatusChange={handleStatusChange}
              />
            )}
          </div>
          {/* <CommonTooltip tooltip={"Priority"}>
            <div
              className={`flex items-center gap-2 ${
                priority === "High"
                  ? "bg-[#FFE9E9] text-red-500"
                  : priority === "Medium"
                  ? "bg-[#F1F3FD] text-custom-blue"
                  : priority === "Low"
                  ? "bg-[#F0EDE9] text-[#78736C]"
                  : ""
              } font-medium text-sm p-1 rounded-md`}
            >
              <PriorityIcon value={priority} />
              <span>{priority}</span>
            </div>
          </CommonTooltip> */}
          <CustomPriorityButton priority={priority} />
          {statusModal && (
            <CustomModal
              isOpen={statusModal}
              onClose={() => setStatusModal(false)}
            >
              <div className="text-center text-custom-black">
                <h2 className="text-2xl font-bold my-4">Change Status</h2>
              </div>
              <div className="w-20 h-20 bg-[#C3E6FF] p-2 rounded-full flex justify-center items-center">
                <div className="bg-custom-blue rounded-full w-16 h-16 flex justify-center items-center">
                  <IoSwapHorizontal size={32} className="text-custom-white" />
                </div>
              </div>
              <div className="text-center text-[#747474]">
                <h2 className="font-medium my-4">
                  are you sure you want to change status?
                </h2>
              </div>

              <div className="flex justify-end items-center gap-5 mt-5">
                <CustomButton
                  title={"Cancel"}
                  onClick={() => setStatusModal(false)}
                  buttonType={"button"}
                  classname={"px-2 py-1 bg-custom-white border rounded-md "}
                />
                <CustomButton
                  title={"Continue"}
                  onClick={() => {
                    updateProjectStatusHandler(_id);
                  }}
                  buttonType={"button"}
                  classname={
                    "bg-gradient-custom text-custom-white px-2 py-1 rounded-md"
                  }
                />
              </div>
            </CustomModal>
          )}
        </div>
        <div className="flex items-center gap-2">
          {(user?.roleId === Roles?.admin ||
            (user?.roleId === Roles?.project_manager &&
              user?._id === project_manager?._id)) && (
            <div
              onClick={(event) => {
                event.stopPropagation();
                navigate("/project/create", {
                  state: {
                    _id: _id ?? "",
                    project_name: project_name ?? "",
                    project_manager: project_manager ?? "",
                    project_start_date: start_date?.split("T")[0] ?? "",
                    project_deadline: end_date?.split("T")[0] ?? "",
                    department: department ?? "",
                    members:
                      assignees?.map((member) => ({
                        name: member?.user_name,
                        value: member._id,
                      })) ?? [],
                    projectStatus: selectedStatus ?? "",
                    priority: priority ?? "",
                    project_description: project_description ?? "",
                  },
                });
              }}
              className="w-7 h-7 rounded-full border text-[#626262] font-semibold flex justify-center items-center"
            >
              <CiEdit />
            </div>
          )}
          {user?.roleId === Roles?.admin &&
            (status === Status?.not_started ||
              status === Status?.cancelled) && (
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  setDeleteProject(!deleteProject);
                }}
                className="w-7 h-7 rounded-full border border-[#FDDADA] bg-[#FFEDED] text-[#EF1212] font-semibold flex justify-center items-center"
              >
                <RiDeleteBin6Line />
              </div>
            )}
          {deleteProject && (
            <CustomModal
              isOpen={deleteProject}
              onClose={() => setDeleteProject(false)}
            >
              <div className="text-center text-custom-black">
                <h2 className="text-2xl font-bold my-4">Delete?</h2>
              </div>
              <div className="w-20 h-20 bg-[#FFECEC] p-2 rounded-full flex justify-center items-center">
                <div className="bg-[#FB0F0F] rounded-full w-16 h-16 flex justify-center items-center">
                  <RiDeleteBinLine size={32} className="text-custom-white" />
                </div>
              </div>
              <div className="text-center text-[#747474]">
                <h2 className="font-medium my-4">
                  Are you sure you want to delete it?
                </h2>
              </div>

              <div className="flex justify-end items-center gap-5 mt-5">
                <CustomButton
                  title={"Cancel"}
                  onClick={() => setDeleteProject(false)}
                  buttonType={"button"}
                  classname={"px-2 py-1 bg-custom-white border rounded-md "}
                />
                <CustomButton
                  title={"Delete"}
                  onClick={() => deleteProjectHandler(_id)}
                  buttonType={"button"}
                  classname={
                    "bg-[#FB0F0F] text-custom-white px-2 py-1 rounded-md"
                  }
                />
              </div>
            </CustomModal>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div>
          <h1 className={`font-medium text-custom-black`}>{project_name}</h1>
          <span className="text-[#747474] text-sm">
            ProjectID - {project_id}
          </span>
        </div>
        <div
        // onClick={(e) => {
        //   e.stopPropagation();
        //   dispatch(setActiveTabInProject("members"));
        //   navigate("/project/view");
        // }}
        >
          <CustomAssignees assignees={[project_manager]} />
        </div>
      </div>
      <div className="border border-dashed" />
      <div className="text-[#747474] text-sm line-clamp-2 text-justify h-10">
        {project_description}
      </div>
      <div className="flex justify-between items-center w-full">
        <CommonTooltip tooltip={"Project Start Date"}>
          <div className="flex items-center gap-2 bg-[#E4F4FF] text-custom-blue font-medium text-sm p-1 rounded-md">
            <MdCalendarMonth size={22} />
            <span>
              {start_date
                ? dateFormatter(start_date, {
                    format: "MMM DD, YYYY",
                    includeTimeForToday: false,
                  })
                : "--"}
            </span>
          </div>
        </CommonTooltip>
        <CommonTooltip tooltip={"Project Deadline (Tentative)"}>
          <div className="flex items-center gap-2 bg-[#FFEDED] text-red-500 font-medium text-sm p-1 rounded-md">
            <MdCalendarMonth size={22} />
            <span>
              {end_date
                ? dateFormatter(end_date, {
                    format: "MMM DD, YYYY",
                    includeTimeForToday: false,
                  })
                : "--"}
            </span>
          </div>
        </CommonTooltip>
      </div>
      <div className="w-full flex justify-between items-center ">
        <CustomAssignees assignees={assignees} />
        <CommonTooltip tooltip={"Completed Task / Total Task"}>
          <span className="bg-[#F1F1F1] text-[#7B7F82] p-1 flex items-center gap-2 rounded-md">
            <FaRegFileLines />
            {completed_task} / {total_task}
          </span>
        </CommonTooltip>
      </div>
      <div className="w-full h-7 py-2">
        <CommonProgressBar progress={progress} />
      </div>
    </div>
  );
};

export default ProjectCard;
