import React from "react";
import { Status } from "../../constant/Constant";

const CustomStatusDropdown = ({ statusRef, handleStatusChange }) => {
  return (
    <div
      ref={statusRef}
      className="w-44 absolute top-8 left-0 mt-1 bg-white text-custom-black border rounded shadow-[2px_3px_10px_6px_#257CBB1F] z-50"
    >
      {Object.values(Status).map((statusOption) => (
        <div
          key={statusOption}
          onClick={() => handleStatusChange(statusOption)}
          className={`cursor-pointer hover:bg-gray-200 px-4 py-1 mx-3 my-1  flex justify-start items-center gap-3 rounded-md ${
            statusOption === Status?.in_progress
              ? "text-custom-status-in-progress"
              : ""
          } ${
            statusOption === Status?.completed
              ? "text-custom-status-completed"
              : ""
          }${
            statusOption === Status?.cancelled
              ? "text-custom-status-cancelled "
              : ""
          }${
            statusOption === Status?.not_started
              ? "text-custom-status-not-started "
              : ""
          }${
            statusOption === Status?.on_hold
              ? "text-custom-status-on-hold "
              : ""
          }`}
        >
          <span
            className={`w-2 h-2 rounded-full ${
              statusOption === Status?.in_progress
                ? "bg-custom-status-in-progress"
                : ""
            } ${
              statusOption === Status?.completed
                ? "bg-custom-status-completed "
                : ""
            }${
              statusOption === Status?.cancelled
                ? "bg-custom-status-cancelled "
                : ""
            }${
              statusOption === Status?.not_started
                ? "bg-custom-status-not-started "
                : ""
            }${
              statusOption === Status?.on_hold ? `bg-custom-status-on-hold` : ""
            }`}
          ></span>
          <span>{statusOption}</span>
        </div>
      ))}
    </div>
  );
};

export default CustomStatusDropdown;
