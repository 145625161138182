import React, { useState, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import CustomButton from "./CustomButton";
/* 
const options = [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "eraser",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "|",
    "outdent",
    "indent",
    "align",
    "|",
    "hr",
    "|",
    "fullsize",
    "brush",
    "|",
    "table",
    "link",
    "file",
    "image",
    "|",
    "undo",
    "redo",
  ];

  Other options for Jodit React Editor
  "bold,italic,underline,strikethrough,eraser,ul,ol,font,table,fontsize,paragraph,lineHeight,superscript,subscript,classSpan,file,image,video,spellcheck,"
  */
import { IoMdSend } from "react-icons/io";

const CustomRichTextEditor = ({
  placeholder = "Start Typing Here...",
  content = "",
  setContent = () => {},
  onSave = () => {},
  onCancel = () => {},
  showButton = true,
}) => {
  const editorRef = useRef(null);

  const config = useMemo(
    () => ({
      width: "100%",
      height: "250px",
      readonly: false,
      placeholder: placeholder || "Start typing here...",
      toolbarButtonSize: "middle",
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "eraser",
        "|",
        "ul",
        "ol",
        "|",
        "font",
        "fontsize",
        "|",
        "outdent",
        "indent",
        "align",
        "|",
        "hr",
        "|",
        "fullsize",
        "brush",
        "|",
        // "table",
        "link",
        // "file",
        "image",
        "|",
        "undo",
        "redo",
      ],
      enableDragAndDropFileToEditor: true,
      uploader: {
        insertImageAsBase64URI: true,
        imageDefaultWidth: "200px",
      },
      filebrowser: {
        ajax: {
          url: null,
        },
      },
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      addNewLine: false,
    }),
    [placeholder]
  );

  // Handle save button click and send content to parent component
  const handleChange = (newContent) => {
    setContent(newContent);
  };
  return (
    <div className="relative w-full rounded-md">
      <JoditEditor
        ref={editorRef}
        value={content}
        config={config}
        tabIndex={1}
        onChange={handleChange}
      />
      {showButton && (
        <div className="absolute z-50 right-3 bottom-2 flex justify-center items-center gap-4">
          <CustomButton
            title={"Cancel"}
            buttonType={"button"}
            onClick={onCancel}
            classname={`border px-4 py-1 text-custom-white rounded-md ${
              content?.length > 0
                ? "bg-custom-black bg-opacity-100 cursor-pointer"
                : "bg-gray-300 text-custom-white bg-opacity-30 cursor-text"
            }`}
          />
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-1 px-4 py-1">
                <span>Send</span>
                <IoMdSend />
              </div>
            }
            buttonType={"button"}
            onClick={onSave}
            classname={` font-semibold rounded text-custom-white ${
              content?.length > 0
                ? "bg-custom-blue bg-opacity-100 cursor-pointer"
                : "bg-gray-300 bg-opacity-30 cursor-text"
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default CustomRichTextEditor;
