import React, { useEffect, useRef, useState } from "react";
import LogoIcon from "../../assets/LogoIcon.png";
import { GoBell } from "react-icons/go";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logout from "./Logout";
import { CiMenuBurger } from "react-icons/ci";
import { CgProfile } from "react-icons/cg";
import { LuLogOut } from "react-icons/lu";
import { ImCancelCircle } from "react-icons/im";
import CustomButton from "./CustomButton";
import CustomModal from "./CustomModal";
import { IoMenuOutline } from "react-icons/io5";
import useOnClickOutside from "../../helper/onClickOutside";
import { TbLogout } from "react-icons/tb";
const baseUrl = process.env.REACT_APP_BASE_URL;
const Header = () => {
  const { user } = useSelector((state) => state.auth);
  const [showAttendance, setShowAttendance] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef();
  const [openDropdown, setOpenDropDown] = useState(false);
  const [logout, setLogout] = useState(false);
  const [showClockInButton, setShowClockInButton] = useState(true);
  const items = [
    {
      name: "Profile",
      id: 1,
      onClick: () => {
        navigate("/profile");
        setOpenDropDown(false);
      },
      icon: <CgProfile />,
    },
    {
      name: "Log Out",
      id: 2,
      onClick: () => {
        setOpenDropDown(false);
        setLogout((logout) => !logout);
      },
      icon: <LuLogOut />,
    },
  ];
  useOnClickOutside(dropdownRef, () => setOpenDropDown(false));

  const callClockIn = () => {
    console.log("clockin");
    setShowAttendance(false);
    setShowClockInButton(false);
  };
  const callClockOut = () => {
    console.log("clockOut");
    setShowAttendance(false);
    setShowClockInButton(true);
  };
  return (
    <>
      <div className="w-full h-16 p-4 flex justify-between items-center md:gap-5 bg-custom-white shadow-md shadow-[#00000014]">
        <div className="flex justify-center items-center gap-40 cursor-pointer">
          <div
            onClick={() => navigate("/dashboard")}
            className="flex justify-center items-center gap-4"
          >
            {/* <CustomButton
              title={<IoMenuOutline size={28} />}
              classname={"text-slate-400 text-lg font-semibold block md:hidden"}
            /> */}
            <div className="bg-custom-blue  rounded-full w-8 h-8 md:w-10 md:h-10 p-2 flex justify-center items-center">
              <img src={LogoIcon} alt="Logo" loading="lazy" />
            </div>
            <h1 className="text-2xl invisible md:visible text-custom-blue font-bold">
              PMS
            </h1>
          </div>
        </div>
        <div className="flex justify-center items-center gap-3 cursor-pointer mr-3">
          {/* {showClockInButton ? (
            <CustomButton
              title="Clock-In"
              classname="border px-5 py-1 rounded-md bg-custom-status-completed text-custom-white"
              onClick={() => {
                setShowAttendance(true);
              }}
            />
          ) : (
            <CustomButton
              title="Clock-Out"
              classname="border px-5 py-1 rounded-md bg-custom-status-cancelled text-custom-white"
              onClick={() => {
                setShowAttendance(true);
              }}
            />
          )} */}
          {/* <CustomButton
            title={
              <div
                className={`flex justify-center items-center gap-2 font-semibold  text-sm p-1 rounded-md`}
              >
                <TbLogout size={18} />
                <span>{showClockInButton ? "Clock In" : "Clock Out"}</span>
              </div>
            }
            classname={`border rounded-md ${
              showClockInButton
                ? "bg-custom-status-completed text-custom-white"
                : "bg-custom-status-cancelled text-custom-white"
            }`}
            onClick={() => {
              setShowAttendance((prev) => !prev);
            }}
          /> */}
          {/* <div className="relative w-8 h-8  bg-[#E7F1F8] rounded-full md:flex justify-center items-center hidden">
            <div className="absolute top-1 right-2 w-1.5 h-1.5 rounded-full bg-pink-900" />
            <GoBell size={20} />
          </div> */}
          <div
            className="flex justify-center items-center md:gap-1 transition-all duration-300 ease-linear "
            onClick={() => setOpenDropDown((openDropdown) => !openDropdown)}
          >
            <div className="w-8 h-8">
              <img
                src={
                  user?.avatar
                    ? `${baseUrl}/${user?.avatar}`
                    : `https://api.dicebear.com/5.x/initials/svg?seed=${user?.user_name}`
                }
                alt={user?.user_name[0]}
                loading="lazy"
                className="w-full h-full rounded-full"
              />
            </div>
            <div className="hidden md:block">
              {openDropdown ? <FaAngleUp /> : <FaAngleDown />}
            </div>
          </div>
        </div>
      </div>
      {openDropdown && (
        <div className="relative" ref={dropdownRef}>
          <div className="absolute right-8 z-50 -top-[18px] bg-custom-white w-3 h-3 rotate-45 border-t border-l" />
          <div className="absolute right-6 -top-3 p-4 rounded-md w-40 z-30 bg-custom-white flex flex-col justify-center items-start gap-3 border shadow-[2px_3px_22px_0px_#9BD3F81A]">
            {items?.map((item) => (
              <button
                onClick={item?.onClick}
                key={item?.id}
                className="w-full hover:bg-custom-blue hover:text-custom-white transition-all duration-300 text-sm font-semibold text-custom-blue  ease-linear p-1 rounded-md cursor-pointer flex items-center gap-4"
              >
                <div className="w-6 h-6 rounded-full bg-[#C8E6FC] text-custom-blue font-semibold p-1 flex justify-center items-center">
                  {item?.icon}
                </div>
                <span>{item?.name}</span>
              </button>
            ))}
          </div>
        </div>
      )}
      {logout && <Logout logout={logout} setLogout={setLogout} />}
      <CustomModal
        isOpen={showAttendance}
        onClose={() => {
          setShowAttendance(false);
          // setShowDepartmentFormModal(false);
        }}
      >
        <div className="text-center text-custom-black">
          <h2 className="text-2xl font-bold my-4">
            Please click on Yes for Clock-In
          </h2>
        </div>
        {/* <div className="w-20 h-20 bg-[#FFECEC] p-2 rounded-full flex justify-center items-center"></div>
        <div className="text-center text-[#747474]">
          <h2 className="font-medium my-4">Please click on Yes for Clock-In</h2>
        </div> */}

        <div className="flex justify-end items-center gap-5 mt-5">
          <CustomButton
            title="Yes"
            onClick={() => callClockIn()}
            buttonType={"button"}
            classname={
              "bg-custom-status-completed px-2 py-1 text-custom-white  border rounded-md "
            }
          />
          <CustomButton
            title="No"
            onClick={() => callClockOut()}
            buttonType={"button"}
            classname={" text-custom-white px-2 py-1 rounded-md bg-slate-500"}
          />
        </div>
      </CustomModal>
    </>
  );
};

export default Header;
