import React, { useState } from "react";

const CustomSwitch = ({ isActive = false, handleStatus = () => {} }) => {
  const [toggleStatus, setToggleStatus] = useState(isActive);
  return (
    <label className="cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        onChange={() => {
          setToggleStatus(!toggleStatus);
          handleStatus(!toggleStatus);
        }}
        checked={toggleStatus}
      />
      <div className="relative w-10 h-5 bg-slate-300 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500" />
    </label>
  );
};

export default CustomSwitch;
