import React, { useEffect, useState, useMemo, useContext } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { apiConnector } from "../../networking/ApiConnector";
import { projectEndPoints } from "../../networking/Endpoints";
import { setLoading } from "../../redux/slice/authSlice";
import { toast } from "react-toastify";
import { dateFormatter, groupByDate } from "../../helper/formatDate";
import { IoArrowForwardSharp } from "react-icons/io5";
import { Roles } from "../../constant/Constant";
import TableHeader from "../common/TableHeader";
import { CustomAlertContext } from "../../context";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// Map field names to display names
const keyName = (field) => {
  const fieldMap = {
    project_name: "Project Name",
    projectId: "Project ID",
    project_manager: "Project Manager",
    department: "Department",
    projectStatus: "Project Status",
    priority: "Priority",
    members: "Members",
    project_start_date: "Project Start Date",
    project_deadline: "Project Deadline",
    project_description: "Project Description",
    title: "Title",
    description: "Description",
    assign_to: "Assign To",
    estimated_time: "Estimated Time",
    hours_logged: "Hours Logged",
    completed_on: "Completed On",
    completed_by: "Completed By",
    assign_by: "Assign By",
    start_date: "Start Date",
    due_date: "Due Date",
    milestone: "Milestone",
    subtask: "Subtask",
  };
  return fieldMap[field] || "";
};

// Render user information with avatar and name
const UserAvatar = ({ user }) => (
  <div className="flex items-center gap-2">
    <img
      src={
        user?.avatar
          ? `${BASE_URL}/${user?.avatar}`
          : `https://api.dicebear.com/5.x/initials/svg?seed=${user?.user_name}`
      }
      alt={user?.user_name?.[0]}
      className="w-7 h-7 rounded-full bg-slate-300 p-0.5"
    />
    <span className="text-xs">{user?.user_name}</span>
  </div>
);

// Render change field details
const ChangeField = ({ fieldName, previousValue, updatedValue }) => {
  if (fieldName === "members") {
    return (
      <div className="flex items-center gap-2">
        <div className="flex gap-1 items-center">
          {previousValue?.map((member) => (
            <UserAvatar key={member._id} user={member} />
          )) || "--"}
        </div>
        <IoArrowForwardSharp />
        <div className="flex gap-1 items-center">
          {updatedValue?.map((member) => (
            <UserAvatar key={member._id} user={member} />
          )) || "--"}
        </div>
      </div>
    );
  }
  if (fieldName === "milestone") {
    return (
      <div className="flex items-center gap-2">
        <span>{previousValue?.milestone_name || "--"}</span>
        <IoArrowForwardSharp />
        <span>{updatedValue?.milestone_name || "--"}</span>
      </div>
    );
  }
  if (fieldName === "department") {
    return (
      <div className="flex items-center gap-2">
        <span>{previousValue?.department_name || "--"}</span>
        <IoArrowForwardSharp />
        <span>{updatedValue?.department_name || "--"}</span>
      </div>
    );
  }
  if (fieldName === "project_manager") {
    return (
      <div className="flex items-center gap-2">
        <UserAvatar user={previousValue} />
        <IoArrowForwardSharp />
        <UserAvatar user={updatedValue} />
      </div>
    );
  }
  if (
    fieldName === "project_start_date" ||
    fieldName === "project_deadline" ||
    fieldName === "completed_on"
  ) {
    return (
      <div className="flex items-center gap-2">
        <span>
          {dateFormatter(previousValue, {
            format: "MMM DD, YYYY",
            includeTimeForToday: false,
          }) || "--"}
        </span>
        <IoArrowForwardSharp />
        <span>
          {dateFormatter(updatedValue, {
            format: "MMM DD, YYYY",
            includeTimeForToday: false,
          }) || "--"}
        </span>
      </div>
    );
  }
  return (
    <div className="flex  items-center gap-2">
      <span>{previousValue || "--"}</span>
      <IoArrowForwardSharp />
      <span>{updatedValue || "--"}</span>
    </div>
  );
};

// Main ProjectHistory Component
const ProjectHistory = ({ projectID }) => {
  const { setToastNotification } = useContext(CustomAlertContext);

  const dispatch = useDispatch();
  const { token, user, loading } = useSelector((state) => state.auth);

  const [openLogIndex, setOpenLogIndex] = useState(-1);
  const [tableData, setTableData] = useState([]);

  const fetchProjectLogs = async () => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        `${projectEndPoints?.PROJECT_LOGS_API}/${projectID}`,
        null,
        { Authorization: `Bearer ${token}` }
      );
      setTableData(response?.data?.changeLogs ?? []);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (projectID && user?.roleId !== Roles?.employee) {
      fetchProjectLogs();
    }
  }, [projectID, token, user?.roleId, dispatch]);

  const groupedLogs = useMemo(() => groupByDate(tableData), [tableData]);

  return loading ? (
    <Skeleton count={5} width={"97%"} height={"22px"} className="mx-5 my-4" />
  ) : (
    <div className="w-full md:h-[82vh] p-4 overflow-auto">
      {Object.entries(groupedLogs).length > 0 ? (
        Object.entries(groupedLogs).map(([date, items], index) => (
          <div
            key={date}
            className="w-full p-2 border rounded-md cursor-pointer my-4"
            onClick={() => setOpenLogIndex(openLogIndex === index ? -1 : index)}
          >
            <div className="flex justify-between items-center">
              <span className="font-semibold">{date}</span>
              <MdOutlineKeyboardArrowDown />
            </div>

            {openLogIndex === index && (
              <section className="w-full">
                <table className="w-full border rounded-lg bg-custom-white overflow-auto">
                  <thead>
                    <TableHeader
                      TableHeads={[
                        "Name",
                        "Change Type",
                        "Changed by",
                        "Date",
                        "Time",
                      ]}
                    />
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td
                          colSpan="5"
                          className="text-center font-semibold text-sm p-3"
                        >
                          <Skeleton count={5} height={22} />
                        </td>
                      </tr>
                    ) : (
                      items.map((item) => (
                        <tr
                          key={item._id}
                          className="border-b hover:bg-slate-100"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <td className="p-3">
                            {item.changes.map((change, i) => (
                              <div key={i}>
                                <span className="font-semibold">
                                  {keyName(change.field_name)}
                                </span>
                                <ChangeField
                                  fieldName={change.field_name}
                                  previousValue={change.previous_value}
                                  updatedValue={change.updated_value}
                                />
                              </div>
                            ))}
                          </td>
                          <td className="text-sm">
                            {item.change_type || "--"}
                          </td>
                          <td className="p-3 text-sm">
                            <UserAvatar user={item.change_by} />
                          </td>
                          <td className="p-3 text-sm">
                            {dateFormatter(item.createdAt, {
                              format: "MMM DD, YYYY",
                              includeTimeForToday: false,
                            })}
                          </td>
                          <td className="p-3 text-sm">
                            {dateFormatter(item.createdAt, {
                              format: "hh:mm A",
                              includeTimeForToday: true,
                            })}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </section>
            )}
          </div>
        ))
      ) : (
        <div>No Data Found</div>
      )}
    </div>
  );
};

export default ProjectHistory;
