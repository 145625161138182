import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { BsInfoCircle } from "react-icons/bs";
import { MdOutlineVisibility } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import PhoneInput from "react-phone-input-2";
import CommonTooltip from "./CommonTooltip";
import { tooltip } from "../../constant/TooltipContent";

const CustomInput = ({
  name = "",
  label = "",
  inputType = "",
  control,
  required = false,
  error,
  placeholder = "",
  labelClassName = "block text-custom-black",
  classname = "",
  isEditable = false,
  max = "",
  min = "",
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const tooltip_content = tooltip[name];

  return inputType === "text-area" ? (
    <>
      <label
        htmlFor={name}
        className={`font-medium flex items-center gap-2 text-sm text-slate-600 ${labelClassName}`}
      >
        <span>
          {label}
          {required && <span className="text-red-500"> *</span>}
        </span>
        <CommonTooltip tooltip={tooltip_content}>
          <BsInfoCircle />
        </CommonTooltip>
      </label>
      <div className="relative">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, value } }) => (
            <textarea
              name={name}
              required={required}
              rows={3}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              readOnly={isEditable}
              className={`border px-3 py-2 mt-2 outline-none w-full rounded-md resize-none text-justify focus:border-custom-blue ${
                isEditable ? "bg-gray-100 cursor-not-allowed" : ""
              } ${classname}`}
            />
          )}
        />
        {error && <p className="text-sm text-red-500">{error}</p>}
      </div>
    </>
  ) : (
    <>
      <label
        htmlFor={name}
        className={`flex items-center gap-2 font-medium text-sm text-slate-600 ${labelClassName}`}
      >
        <span>
          {label}
          {required && <span className="text-red-500"> *</span>}
        </span>
        <CommonTooltip tooltip={tooltip_content}>
          <BsInfoCircle />
        </CommonTooltip>
      </label>
      <div className="relative">
        {inputType === "password" &&
          (showPassword ? (
            <MdOutlineVisibility
              size={22}
              onClick={() => setShowPassword(!showPassword)}
              className="absolute text-[#96A9B7] cursor-pointer right-2 top-4"
            />
          ) : (
            <RiEyeCloseLine
              size={22}
              onClick={() => setShowPassword(!showPassword)}
              className="absolute text-[#96A9B7] cursor-pointer right-2 top-4"
            />
          ))}

        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, value } }) =>
            inputType === "tel" ? (
              <PhoneInput
                country={"in"}
                value={value ? value.toString() : ""}
                placeholder={placeholder}
                onBlur={onBlur}
                onChange={(value, data, event, formattedValue) => {
                  onChange(formattedValue);
                }}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                disabled={isEditable}
                enableSearch={true}
                enableClickOutside={true}
                containerStyle={{
                  background: isEditable ? "#EFEFEF" : "#FFFFFF",
                }}
                inputStyle={{ background: isEditable ? "#EFEFEF" : "#FFFFFF" }}
                className={` mt-2 text-sm outline-none w-full rounded-md focus:border-custom-blue ${
                  isEditable ? "bg-gray-100 cursor-not-allowed" : ""
                } ${classname}`}
              />
            ) : (
              <input
                tabIndex={0}
                name={name}
                required={required}
                placeholder={placeholder}
                type={showPassword ? "text" : inputType}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                readOnly={isEditable}
                max={max}
                min={min}
                className={`border px-3 py-2 mt-2 text-sm outline-none w-full rounded-md focus:border-custom-blue ${
                  isEditable ? "bg-gray-100 cursor-not-allowed" : ""
                } ${classname}`}
              />
            )
          }
        />
        {error && <p className="text-sm text-red-500">{error}</p>}
      </div>
    </>
  );
};

export default CustomInput;
