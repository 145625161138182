import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setLoading } from "../../redux/slice/authSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { taskEndPoints } from "../../networking/Endpoints";

import { toast } from "react-toastify";
import { CustomButton } from "../common";
import SubTask from "./subtask/subtask";
import TaskDetails from "./TaskDetails";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import Comments from "./Comments";
import Timeline from "./Timeline";
import TaskHistory from "./TaskHistory";
import { setActiveTabInTask } from "../../redux/slice/taskSlice";
import { CustomAlertContext } from "../../context";
import { Roles } from "../../constant/Constant";
const TaskView = () => {
  const dispatch = useDispatch();
  const { setToastNotification } = useContext(CustomAlertContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { token, user } = useSelector((state) => state.auth);
  const { activeTabInTask } = useSelector((state) => state.task);
  const [taskDetails, setTaskDetails] = useState(null);
  const task_id = location?.state?.id;
  const handleTabClick = (tab) => {
    dispatch(setActiveTabInTask(tab));
  };
  const fetchTaskDetails = async () => {
    dispatch(setLoading(true));
    try {
      const task_response = await apiConnector(
        "GET",
        `${taskEndPoints?.TASK_API}/${task_id}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setTaskDetails(task_response?.data?.data?.taskDetail);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (task_id) {
      fetchTaskDetails();
    }
  }, [task_id]);
  return (
    <div className="w-full h-full bg-custom-white rounded-lg">
      <section className="relative w-full bg-[#E6F0F6] rounded-t-lg flex justify-start gap-2 md:gap-10 overflow-x-auto">
        <div className="px-4 py-2">
          <CustomButton
            title={<MdOutlineKeyboardBackspace size={24} />}
            buttonType={"button"}
            onClick={() => navigate(-1)}
            classname={`w-8 h-8 rounded-full flex justify-center items-center bg-custom-blue text-custom-white`}
          />
        </div>
        <h2
          onClick={() => handleTabClick("task_details")}
          className={`cursor-pointer p-4 font-semibold text-sm ${
            activeTabInTask === "task_details"
              ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
              : ""
          }`}
        >
          Task Details
        </h2>
        <h2
          onClick={() => handleTabClick("sub_task")}
          className={`cursor-pointer p-4 font-semibold text-sm ${
            activeTabInTask === "sub_task"
              ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
              : ""
          }`}
        >
          Sub Task
        </h2>
        <h2
          onClick={() => handleTabClick("comment")}
          className={`cursor-pointer p-4 font-semibold text-sm ${
            activeTabInTask === "comment"
              ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
              : ""
          }`}
        >
          Comment
        </h2>
        {user?.roleId !== Roles?.employee && (
          <h2
            onClick={() => handleTabClick("History")}
            className={`cursor-pointer p-4 font-semibold text-sm ${
              activeTabInTask === "History"
                ? "text-custom-blue transition-colors border-b-2 border-custom-blue"
                : ""
            }`}
          >
            History
          </h2>
        )}
      </section>
      <section className="w-full h-full">
        {activeTabInTask === "task_details" && (
          <TaskDetails taskDetails={taskDetails} />
        )}
        {activeTabInTask === "sub_task" && (
          <SubTask
            taskActiveTab={activeTabInTask}
            task_id={taskDetails?._id}
            start_date={taskDetails?.start_date?.split("T")[0] ?? ""}
            due_date={
              taskDetails?.due_date
                ? taskDetails?.due_date?.split("T")[0]
                : taskDetails?.project?.due_date?.split("T")[0] ?? ""
            }
            taskMembers={taskDetails?.assign_to}
          />
        )}
        {activeTabInTask === "comment" && (
          <Comments taskId={taskDetails?._id} />
        )}
        {activeTabInTask === "History" && (
          <TaskHistory taskId={taskDetails?._id} />
        )}
      </section>
    </div>
  );
};

export default TaskView;
