import axios from "axios";
import { ApiError } from "../helper/ApiError";
import { store } from "..";
import { setToken, setUser } from "../redux/slice/authSlice";
import { toast } from "react-toastify";

export const axiosInstance = axios.create({});

export const apiConnector = (method, url, bodyData, headers, params) => {
  return axiosInstance({
    method: `${method}`,
    url: `${url}`,
    data: bodyData ? bodyData : null,
    headers: headers ? headers : null,
    params: params ? params : null,
  });
};

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    const { token } = store.getState().auth;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      store.dispatch(setToken(null));
      store.dispatch(setUser(null));
      toast.error(error?.response?.data?.message);
      window.href = "/login";
    }
    const errorMessage = ApiError(error);
    return Promise.reject(errorMessage);
  }
);
