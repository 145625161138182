import React from "react";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  return (
    <div className="sticky top-0 z-10 bg-[#E9F6FB]">
      {" "}
      <div className=" py-[18px] bg-[#E9F6FB] z-10 2xl:px-0 xl:px-0 lg:px-0 px-[15px]">
        <div className="container mx-auto">
          <div className="flex items-center justify-between">
            <div className="">
              <img
                className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[100px]"
                src="../assets/logo.png"
              ></img>
            </div>
            <button
              onClick={() => navigate("/login")}
              className="2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] text-[14px] bg-[linear-gradient(98.66deg,_#0D62A2_-5.19%,_#3F9BDF_53.88%,_#0D62A1_131.31%)] text-white 2xl:px-[30px] xl:px-[30px] lg:px-[30px] md:px-[15px] px-[15px] 2xl:py-[15px] xl:py-[15px] lg:py-[15px] md:py-[15px] py-[5px] rounded-[12px]"
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
