import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CustomActionDropDown,
  CustomButton,
  CustomModal,
  NoDataFound,
  Pagination,
  PaginationText,
  TableHeader,
} from "../../common";
import { MdOutlineEdit, MdOutlineFilterAlt } from "react-icons/md";
import { IoMdSearch } from "react-icons/io";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "../../../helper/onClickOutside";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import CreateDesignation from "./CreateDesignation";
import { fetchDesignation } from "../../../redux/slice/designationSlice";
import { dateFormatter } from "../../../helper/formatDate";
// import { apiConnector } from "../../../networking/ApiConnector";
// import { setLoading } from "../../../redux/slice/authSlice";
// import { designationEndPoints } from "../../../networking/Endpoints";
// import { RiDeleteBinLine } from "react-icons/ri";
import { CustomAlertContext } from "../../../context";

const Designation = () => {
  const actionRef = useRef(),
    dispatch = useDispatch(),
    { token } = useSelector((state) => state.auth),
    { setToastNotification } = useContext(CustomAlertContext),
    { designationLoading } = useSelector((state) => state.designation),
    [showDesignation, setShowDesignation] = useState(false),
    [designationDetails, setDesignationDetails] = useState(null),
    [designation, setDesignation] = useState([]),
    [designationTableData, setDesignationTableData] = useState([]),
    [searchString, setSearchString] = useState(""),
    [designationCount, setDesignationCount] = useState(0),
    [openDropdownIndex, setOpenDropdownIndex] = useState(null),
    [itemsPerPage, setItemsPerPage] = useState(100),
    [currentPage, setCurrentPage] = useState(1);
  const [showDesignationDeleteModal, setShowDesignationDeleteModal] =
    useState(false);
  const TableHeads = [
    "S.No",
    "Department",
    "Designation",
    "Created At",
    "Last Updated At",
    "Status",
    "Actions",
  ];

  useOnClickOutside(actionRef, () => setOpenDropdownIndex(null));

  const fetchDesignations = async () => {
    try {
      const response = await dispatch(
        fetchDesignation({ token, currentPage, itemsPerPage })
      ).unwrap();

      if (response) {
        setDesignation(response?.designation);
        setDesignationTableData(response?.designation);
        setDesignationCount(response?.count_designation);
      }
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };

  useEffect(() => {
    fetchDesignations();
  }, [currentPage, itemsPerPage]);

  const filterDesignation = (e) => {
    const val = e?.target?.value;
    setSearchString(e?.target?.value);
    if (val?.length > 0) {
      const filteredDesignation = designation?.filter(
        (item) =>
          item?.department?.department_name
            .toLowerCase()
            .includes(val.toLowerCase()) ||
          item?.designation.toLowerCase().includes(val.toLowerCase()) ||
          dateFormatter(item?.createdAt)
            .toLowerCase()
            .includes(val.toLowerCase()) ||
          dateFormatter(item?.updatedAt)
            .toLowerCase()
            .includes(val.toLowerCase())
      );
      setDesignationTableData(filteredDesignation);
    } else {
      setDesignationTableData(designation);
    }
  };

  const pageCount = Math.ceil(designationCount / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
  };
  // const deleteDesignation = async (designation_id) => {
  //   dispatch(setLoading(true));
  //   try {
  //     const department_response = await apiConnector(
  //       "DELETE",
  //       `${designationEndPoints?.DESIGNATION_API}/${designation_id}`,
  //       null,
  //       {
  //         Authorization: `Bearer ${token}`,
  //       }
  //     );
  //     toast.success(department_response?.data?.message);
  //     if (fetchDesignations) {
  //       fetchDesignations();
  //     }
  //     setShowDesignationDeleteModal(false);
  //   } catch (error) {
  //     setToastNotification(error?.message, "error");
  //   } finally {
  //     dispatch(setLoading(false));
  //   }
  // };
  return (
    <div className="">
      <section className="flex md:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-t-md">
        <h1 className="font-semibold text-lg">Designation List</h1>
        <div className="flex md:flex-row flex-col justify-center items-center gap-4">
          <div className="relative">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              type="text"
              placeholder="Search"
              onChange={(e) => filterDesignation(e)}
              value={searchString}
              className="border px-6 py-2 font-semibold text-sm outline-none rounded-md"
            />
          </div>
          <CustomButton
            title={
              <div className="flex justify-center items-center font-semibold">
                <MdOutlineFilterAlt size={20} />
                <span>Filters</span>
              </div>
            }
            buttonType="submit"
            classname="bg-custom-white text-slate-400 p-2 font-semibold text-sm rounded-md border "
          />
          <CustomButton
            title={
              <div className="flex justify-center items-center gap-2 ">
                <FaPlusCircle />
                <span>Add Designation</span>
              </div>
            }
            buttonType="button"
            onClick={() =>
              setShowDesignation((showDesignation) => !showDesignation)
            }
            classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
          />
        </div>
      </section>
      <section className="w-full overflow-auto">
        <table className="w-full border bg-custom-white rounded-lg overflow-auto">
          <thead>
            <TableHeader TableHeads={TableHeads} />
          </thead>
          {designationLoading ? (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads.length}
                  className="text-center font-semibold text-sm p-3"
                >
                  <Skeleton
                    count={5}
                    width={"100%"}
                    height={"22px"}
                    className="my-2"
                  />
                </td>
              </tr>
            </tbody>
          ) : designationTableData && designationTableData?.length > 0 ? (
            <>
              <tbody>
                {designationTableData?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className="w-full border-b hover:bg-slate-100"
                    >
                      <td className="p-2 text-sm font-medium">
                        {(currentPage - 1) * itemsPerPage + index + 1}
                      </td>
                      <td className="p-2 text-sm">
                        {item?.department
                          ? item?.department?.department_name
                          : "-"}
                      </td>
                      <td className="p-2 text-sm">
                        {item?.designation ?? "-"}
                      </td>
                      <td className="p-2 text-sm">
                        {item?.createdAt
                          ? dateFormatter(item?.createdAt, {
                              format: "MMM DD, YYYY hh:mm A",
                              includeTimeForToday: true,
                            })
                          : "--"}
                      </td>
                      <td className="p-2 text-sm">
                        {item?.createdAt !== item?.updatedAt
                          ? dateFormatter(item?.updatedAt, {
                              format: "MMM DD, YYYY hh:mm A",
                              includeTimeForToday: true,
                            })
                          : "-"}
                      </td>
                      <td>
                        <div className="flex items-center text-sm text-start gap-2">
                          <div
                            className={`w-3 h-3  rounded-full ${
                              item?.isActive ? "bg-green-500" : "bg-red-500"
                            }`}
                          />
                          <span>{item?.isActive ? "Active " : "Inactive"}</span>
                        </div>
                      </td>
                      <td className="p-2 flex justify-center items-center cursor-pointer">
                        <div
                          onClick={() => {
                            setDesignationDetails(item);
                            setShowDesignation(true);
                          }}
                          className="p-1 border border-custom-blue rounded-md cursor-pointer"
                        >
                          <MdOutlineEdit className="text-custom-blue" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={TableHeads?.length}
                  className="text-center font-semibold text-sm p-4"
                >
                  <NoDataFound />
                </td>
              </tr>
            </tbody>
          )}
          <tfoot className="relative">
            <tr>
              <td className="text-slate-400 text-sm px-2 py-4" colSpan={6}>
                <PaginationText
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  dataCount={designationCount}
                  dataLength={designation?.length}
                />
              </td>
              <td className="absolute right-2 top-1.5">
                <Pagination
                  handlePageClick={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  pageCount={pageCount}
                  name={"pageSize"}
                  onChange={(e) => setItemsPerPage(e?.target?.value)}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
      {showDesignation && (
        <CreateDesignation
          designationDetails={designationDetails}
          setDesignationDetails={setDesignationDetails}
          showDesignation={showDesignation}
          setShowDesignation={setShowDesignation}
          getDesignation={fetchDesignations}
        />
      )}
    </div>
  );
};

export default Designation;
