import React from "react";

const CustomModal = ({
  isOpen,
  onClose,
  classname = "w-[450px]",
  children,
}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className="fixed inset-0 z-[999] grid place-items-center bg-custom-black bg-opacity-30 backdrop-blur-0">
      <div
        className={`relative ${classname} rounded-lg border border-[#E9F6FF] p-6 flex flex-col items-center bg-custom-white`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          onClick={onClose}
          className="absolute -top-3 -right-3 w-7 h-7 rounded-full bg-[#E7EFF5] text-custom-black font-semibold flex justify-center items-center cursor-pointer"
        >
          X
        </div>
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
