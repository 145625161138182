import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowRight } from "react-icons/fa";

// Custom Previous Arrow Component
const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button className={`${className}`} onClick={onClick}>
      <div className="2xl:flex xl:flex lg:flex hidden bg-[#E8E9E9] items-center justify-center h-[40px] w-[40px] rounded-[50%] absolute right-0">
        <img src="../assets/arrow-2.png" alt="Previous" />
      </div>
    </button>
  );
};

// Custom Next Arrow Component
const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button className={`${className}`} onClick={onClick}>
      <div className="2xl:flex xl:flex lg:flex hidden bg-[#E8E9E9] items-center justify-center h-[40px] w-[40px] rounded-[50%]">
        <img src="../assets/arrow-1.png" alt="Next" />
      </div>
    </button>
  );
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 1024, // Tablet breakpoint
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600, // Mobile breakpoint
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, // Hide arrows on mobile for better user experience
      },
    },
  ],
};

function Customer() {
  return (
    <div className="container mx-auto 2xl:pb-[140px] xl:pb-[140px] lg:pb-[140px] md:pb-[60px] pb-[60px] px-4 sm:px-2">
      {" "}
      {/* Add padding for small screens */}
      <div className="text-center mb-[40px]">
        <span className="text-center relative">
          <span className="2xl:text-[40px] xl:text-[40px] lg:text-[40px] md:text-[36px] text-[32px] font-semibold">
            {" "}
            {/* Responsive font size */}
            Our customers
          </span>
          <span className="absolute right-0">
            <img src="../assets/needs-vector.png" alt="Vector" />
          </span>
        </span>
      </div>
      <Slider {...settings}>
        <div>
          <div className="shadow-[2px_3px_22px_0px_#9BD3F81A] border border-[#2780C11C] 2xl:p-[25px] xl:p-[25px] lg:p-[25px] md:p-[20px] p-[15px] rounded-[16px] mx-[10px]">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src="../assets/clients.png"
                  alt="Client"
                  className="w-[50px] h-[50px]"
                />{" "}
                {/* Responsive image */}
                <div className="ml-2">
                  {" "}
                  {/* Adjust margin */}
                  <p className="text-[#1F1F11] text-[18px] sm:text-[16px] font-semibold">
                    Mark Smith
                  </p>
                  <p className="text-[#565656] text-[16px] sm:text-[14px] font-normal">
                    USA
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-1">
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
              </div>
            </div>
            <div className="relative mt-5">
              <div className="para-box px-5">
                <p className="deatils text-[#545454] 2xl:text-[17px] xl:text-[17px] lg:text-[17px] text-[14px] font-normal px-3">
                  {" "}
                  {/* Responsive font */}
                  will be pursued by the adipiscing developer But at the same
                  time they happened with great labor and pain. For to come to
                  the smallest detail, no one should practice any kind of work
                  unless he derives some benefit from it. But at the same time
                  they happened with great labor and pain. For to come to the
                  smallest detail, no one should practice any kind of work
                  unless he derives some benefit from it.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="shadow-[2px_3px_22px_0px_#9BD3F81A] border border-[#2780C11C] 2xl:p-[25px] xl:p-[25px] lg:p-[25px] md:p-[20px] p-[15px] rounded-[16px] mx-[10px]">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src="../assets/clients.png"
                  alt="Client"
                  className="w-[50px] h-[50px]"
                />
                <div className="ml-2">
                  <p className="text-[#1F1F11] text-[18px] sm:text-[16px] font-semibold">
                    Mark Smith
                  </p>
                  <p className="text-[#565656] text-[16px] sm:text-[14px] font-normal">
                    USA
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-1">
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
              </div>
            </div>
            <div className="relative mt-5">
              <div className="para-box px-5">
                <p className="deatils 2xl:h-[100px] xl:h-[100px] lg:h-[100px] text-[#545454] 2xl:text-[17px] xl:text-[17px] lg:text-[17px] text-[14px] font-normal px-3">
                  {" "}
                  {/* Responsive font */}
                  The PMS project has an intuitive and user-friendly interface.
                  The dashboard provides a clear overview of tasks, deadlines,
                  and team assignments, making it easy for users to navigate and
                  manage their projects efficiently. However, the design could
                  benefit from more customization options, such as themes or the
                  ability to rearrange widgets based on user preferences.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="shadow-[2px_3px_22px_0px_#9BD3F81A] border border-[#2780C11C] 2xl:p-[25px] xl:p-[25px] lg:p-[25px] md:p-[20px] p-[15px] rounded-[16px] mx-[10px]">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src="../assets/clients.png"
                  alt="Client"
                  className="w-[50px] h-[50px]"
                />
                <div className="ml-2">
                  <p className="text-[#1F1F11] text-[18px] sm:text-[16px] font-semibold">
                    Mark Smith
                  </p>
                  <p className="text-[#565656] text-[16px] sm:text-[14px] font-normal">
                    USA
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-1">
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
              </div>
            </div>
            <div className="relative mt-5">
              <div className="para-box px-5">
                <p className="deatils 2xl:h-[100px] xl:h-[100px] lg:h-[100px] text-[#545454] 2xl:text-[17px] xl:text-[17px] lg:text-[17px] text-[14px] font-normal px-3">
                  {" "}
                  {/* Responsive font */}
                  The feature set of the PMS project is robust, including task
                  management, resource allocation, Gantt charts, and real-time
                  collaboration tools. The integration with third-party apps
                  like Slack and Google Drive adds significant value. However,
                  some advanced features like budget tracking or risk management
                  are missing, which might be a drawback for larger
                  organizations.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="shadow-[2px_3px_22px_0px_#9BD3F81A] border border-[#2780C11C] 2xl:p-[25px] xl:p-[25px] lg:p-[25px] md:p-[20px] p-[15px] rounded-[16px] mx-[10px]">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src="../assets/clients.png"
                  alt="Client"
                  className="w-[50px] h-[50px]"
                />
                <div className="ml-2">
                  <p className="text-[#1F1F11] text-[18px] sm:text-[16px] font-semibold">
                    Mark Smith
                  </p>
                  <p className="text-[#565656] text-[16px] sm:text-[14px] font-normal">
                    USA
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-1">
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
                <img
                  className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto w-[15px]"
                  src="../assets/rating.png"
                  alt="Rating"
                />
              </div>
            </div>
            <div className="relative mt-5">
              <div className="para-box px-5">
                <p className="deatils 2xl:h-[100px] xl:h-[100px] lg:h-[100px] text-[#545454] 2xl:text-[17px] xl:text-[17px] lg:text-[17px] text-[14px] font-normal px-3">
                  {" "}
                  {/* Responsive font */}
                  The system performs exceptionally well, with minimal load
                  times and no noticeable lag, even with a large number of
                  concurrent users. The cloud-based architecture ensures
                  reliability and uptime. That said, the offline mode could use
                  some improvements, as syncing data when reconnected
                  occasionally faces issues..
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Add more slides as needed */}
      </Slider>
    </div>
  );
}

export default Customer;
