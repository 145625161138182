import React, { useContext, useEffect, useRef, useState } from "react";
import TextEditor from "./TextEditor";
import { setLoading } from "../../redux/slice/authSlice";
import { apiConnector } from "../../networking/ApiConnector";
import { useDispatch, useSelector } from "react-redux";
import { commentEndPoints } from "../../networking/Endpoints";
import { toast } from "react-toastify";
import { dateFormatter, groupByDate } from "../../helper/formatDate";
import { BiEditAlt } from "react-icons/bi";
import Skeleton from "react-loading-skeleton";
import "quill/dist/quill.snow.css";
import { IoMdSend } from "react-icons/io";
import { CustomAlertContext } from "../../context";
import { CustomRichTextEditor } from "../common";

const baseUrl = process.env.REACT_APP_BASE_URL;
const Comments = ({ taskId }) => {
  const { setToastNotification } = useContext(CustomAlertContext);
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const { token, user, loading } = useSelector((state) => state.auth);
  const [content, setContent] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [editComment, setEditComment] = useState(null);
  const [comments, setComments] = useState(null);

  const fetchComments = async () => {
    dispatch(setLoading(true));
    try {
      const comment_response = await apiConnector(
        "GET",
        `${commentEndPoints?.COMMENT_API}/${taskId}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setComments(comment_response?.data?.data?.comments);
    } catch (error) {
      setToastNotification(error?.message, "error");
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (taskId) fetchComments();
  }, [taskId]);

  const groupedComments = groupByDate(comments ?? []);

  const handleAddComment = async () => {
    // Clean unwanted empty paragraphs
    const cleanedContent = content.replace(/<p><br><\/p>/g, "").trim();

    if (cleanedContent) {
      dispatch(setLoading(true));
      try {
        const apiMethod = editComment ? "PUT" : "POST";
        const apiUrl = editComment
          ? `${commentEndPoints?.COMMENT_API}/${editComment?._id}`
          : `${commentEndPoints?.COMMENT_API}/${taskId}`;
        const comment_response = await apiConnector(
          apiMethod,
          apiUrl,
          { content: cleanedContent },
          {
            Authorization: `Bearer ${token}`,
          }
        );
        toast.success(`${comment_response?.data?.message}`);
        if (isEditMode) {
          setIsEditMode(false);
          setEditComment(null);
        }
        setContent("");

        if (fetchComments) fetchComments();
      } catch (error) {
        setToastNotification(error?.message, "error");
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const HtmlContent = ({ htmlString }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  };
  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [groupedComments]);
  return (
    <div className="relative w-full h-full">
      {loading ? (
        <div className="p-2">
          {[...Array(4)].map((_, index) => (
            <div key={index} className="mb-4">
              <div className="flex gap-4 items-center my-2">
                <Skeleton circle height={28} width={28} />
                <Skeleton />
              </div>
              <Skeleton count={2} />
            </div>
          ))}
        </div>
      ) : (
        <section className="w-full h-[calc(64vh-100px)] p-2 overflow-auto">
          {Object?.entries(groupedComments)?.length > 0 ? (
            Object?.entries(groupedComments)?.map(([date, items]) => (
              <div key={date}>
                <h2 className="font-normal flex items-center my-2">
                  <div className="flex-grow border-t border-slate-200" />
                  <div className="border rounded-full px-2 py-1 text-sm text-slate-400">
                    {date}
                  </div>
                  <div className="flex-grow border-t border-slate-200" />
                </h2>
                {items?.map((item, index) => (
                  <div
                    key={item?._id}
                    className={`${
                      items?.length - 1 !== index && "border-b pb-3"
                    }  w-full py-3 px-5 space-y-1 group`}
                  >
                    <div className="flex justify-between items-center gap-2 ">
                      <div className="flex items-center gap-4">
                        <img
                          src={
                            item?.author?.avatar
                              ? `${baseUrl}/${item?.author?.avatar}`
                              : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.author?.user_name}`
                          }
                          alt={item?.author?.user_name[0] || "image"}
                          loading="lazy"
                          className="w-7 h-7 rounded-full"
                        />
                        <span className="text-custom-black text-sm font-semibold">
                          {item?.author?._id === user?._id
                            ? "You"
                            : item?.author?.user_name}
                        </span>
                        <span className="text-xs text-gray-400 ">
                          {item?.createdAt && (
                            <>
                              {dateFormatter(item?.createdAt, {
                                format: "MMM DD, YYYY hh:mm A",
                                includeTimeForToday: true,
                              })}
                              {item?.createdAt !== item?.updatedAt && " | "}
                              {item?.createdAt !== item?.updatedAt && (
                                <>
                                  Edited{" "}
                                  {dateFormatter(item?.updatedAt, {
                                    format: "MMM DD, YYYY hh:mm A",
                                    includeTimeForToday: true,
                                  })}
                                </>
                              )}
                            </>
                          )}
                        </span>
                      </div>

                      {item?.author?._id === user?._id && (
                        <span
                          onClick={(event) => {
                            event.stopPropagation();
                            setIsEditMode(true);
                            setEditComment(item);
                          }}
                          className="text-gray-400 w-8 h-8 rounded-full flex justify-center items-center bg-[#F2F2F2] opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
                        >
                          <BiEditAlt size={21} />
                        </span>
                      )}
                    </div>
                    <HtmlContent htmlString={item?.content} />
                  </div>
                ))}
                <div ref={scrollRef} />
              </div>
            ))
          ) : (
            <div className="text-center font-semibold text-sm ">
              No Comments available
            </div>
          )}
        </section>
      )}
      <section className="w-full bg-slate-10">
        <TextEditor
          taskId={taskId}
          fetchComments={fetchComments}
          editComment={editComment}
          setEditComment={setEditComment}
        />
        {/* <CustomRichTextEditor
          placeholder={isEditMode ? "" : "Start Typing Here..."}
          content={isEditMode ? editComment?.content : content}
          setContent={setContent}
          onSave={handleAddComment}
          onCancel={() => {
            setIsEditMode(false);
            setContent("");
          }}
        /> */}
      </section>
    </div>
  );
};
export default Comments;
