import React from "react";

const CustomButton = ({
  tabIndex,
  title,
  disabled,
  buttonType,
  classname,
  onClick = () => {},
}) => {
  return (
    <button
      tabIndex={tabIndex}
      type={buttonType}
      className={classname}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default CustomButton;
