import React, { useContext, useEffect, useRef, useState } from "react";
import ProjectCard from "./ProjectCard";
import {
  CustomButton,
  CustomDrawer,
  FilterSelect,
  NoDataFound,
  Pagination,
  PaginationText,
} from "../common";
import { MdOutlineFilterAlt } from "react-icons/md";
import { FaPlusCircle } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import {
  getAllProject,
  setActiveTabInProject,
} from "../../redux/slice/projectSlice";
import {
  filterPriorityOptions,
  filterStatusOptions,
  Roles,
} from "../../constant/Constant";
import { fetchDepartment } from "../../redux/slice/departmentSlice";
import { fetchAllEmployees } from "../../redux/slice/employeeSlice";
import useOnClickOutside from "../../helper/onClickOutside";
import { setProjectFilter } from "../../redux/slice/filterSlice";
import { CustomAlertContext } from "../../context";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Project = () => {
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    drawerRef = useRef(),
    { setToastNotification } = useContext(CustomAlertContext),
    [searchString, setSearchString] = useState(""),
    { token, user } = useSelector((state) => state.auth),
    { employeeData } = useSelector((state) => state.employee),
    { departmentData } = useSelector((state) => state.department),
    { projectLoading } = useSelector((state) => state.project),
    { projectFilter } = useSelector((state) => state.filter),
    [isEditMode, setIsEditMode] = useState(false),
    [openFilterDrawer, setOpenFilterDrawer] = useState(false),
    [projects, setProjects] = useState([]),
    [tableData, setTableData] = useState([]),
    [projectCount, setProjectCount] = useState(0),
    [itemsPerPage, setItemsPerPage] = useState(100),
    [currentPage, setCurrentPage] = useState(1);

  useOnClickOutside(drawerRef, () => setOpenFilterDrawer(false));
  const fetchProjects = async (
    department = projectFilter?.department,
    project_member = projectFilter?.project_member,
    project_manager = projectFilter?.project_manager,
    projectStatus = projectFilter?.projectStatus,
    priority = projectFilter?.priority
  ) => {
    const queryParams = {};
    if (department) queryParams.department = department;
    if (project_member) queryParams.members = project_member;
    if (project_manager) queryParams.project_manager = project_manager;
    if (projectStatus) queryParams.projectStatus = projectStatus;
    if (priority) queryParams.priority = priority;
    if (currentPage && itemsPerPage) {
      queryParams.page_number = currentPage;
      queryParams.page_size = itemsPerPage;
    }
    try {
      const response = await dispatch(
        getAllProject({
          token,
          queryParams,
        })
      ).unwrap();

      if (response) {
        setProjects(response?.projects);
        setTableData(response?.projects);
        setProjectCount(response?.project_count);
      }
      setOpenFilterDrawer(false);
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [currentPage, itemsPerPage]);

  const pageCount = Math.ceil(projectCount / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event?.selected + 1);
    fetchProjects();
  };
  const filterProject = (e) => {
    const val = e?.target?.value;
    setSearchString(e?.target?.value);
    if (val?.length > 0) {
      const filteredProject = projects?.filter(
        (item) =>
          item?.project_name.toLowerCase().includes(val.toLowerCase()) ||
          item?.projectStatus.toLowerCase().includes(val.toLowerCase())
      );
      setTableData(filteredProject);
    } else {
      setTableData(projects);
    }
  };
  const fetchDepartments = async () => {
    try {
      await dispatch(fetchDepartment({ token }));
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };
  const fetchEmployees = async () => {
    try {
      await dispatch(fetchAllEmployees({ token }));
    } catch (error) {
      setToastNotification(error?.message, "error");
    }
  };
  useEffect(() => {
    if (user?.roleId !== Roles?.employee) {
      if (
        !departmentData ||
        (departmentData?.page_number && departmentData?.page_size)
      ) {
        fetchDepartments();
      }
      if (
        !employeeData ||
        (employeeData?.page_number && employeeData?.page_size)
      ) {
        fetchEmployees();
      }
    }
  }, []);

  const departmentOptions = departmentData?.departments
    ?.filter((item) => item?.isActive === true)
    ?.map((department) => ({
      name: department?.department_name,
      value: department?._id,
    }));

  departmentOptions?.unshift({ name: "All", value: "" });

  const filteredEmployees = employeeData?.employees?.filter((item) => {
    return (
      item?.roleId !== Roles?.admin &&
      item?.roleId !== Roles?.project_manager &&
      item?.isActive === true
    );
  });
  const projectManagerOptions = employeeData?.employees
    ?.filter((item) => {
      return (
        (item?.roleId === Roles?.admin ||
          item?.roleId === Roles?.project_manager) &&
        item?.isActive === true
      );
    })
    ?.map((item) => ({
      name: item?.user_name,
      value: item?._id,
      designation: item?.designation?.designation,
      avatar: item?.avatar
        ? `${baseUrl}/${item?.avatar}`
        : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`,
    }));
  const employeeOptions = filteredEmployees?.map((item) => ({
    name: item?.user_name,
    value: item?._id,
    designation: item?.designation?.designation,
    avatar: item?.avatar
      ? `${baseUrl}/${item?.avatar}`
      : `https://api.dicebear.com/5.x/initials/svg?seed=${item?.user_name}`,
  }));
  employeeOptions?.unshift({ name: "All", value: "" });
  projectManagerOptions?.unshift({ name: "All", value: "" });
  const filterProjectApiHandler = () => {
    const {
      department,
      project_member,
      project_manager,
      projectStatus,
      priority,
    } = projectFilter;
    fetchProjects(
      department,
      project_member,
      project_manager,
      projectStatus,
      priority
    );
  };
  return (
    <div className="w-full bg-custom-white rounded-md p-5 space-y-2">
      <section className="flex md:flex-row flex-col gap-2 justify-between bg-custom-white items-center p-2 rounded-md">
        <h1 className="font-semibold">Project List</h1>
        <div className="flex flex-col md:flex-row justify-center items-center gap-4">
          <div className="relative ">
            <IoMdSearch className="absolute z-10 top-3 left-1 text-slate-400" />
            <input
              name="searchString"
              label=""
              type="text"
              placeholder="Search"
              onChange={(e) => filterProject(e)}
              value={searchString}
              className="bg-custom-white px-6 py-2 text-sm font-semibold border outline-none rounded-md"
            />
          </div>
          <CustomButton
            title={
              <div className="relative flex justify-center items-center font-semibold">
                {(projectFilter?.department ||
                  projectFilter?.project_manager ||
                  projectFilter?.project_member ||
                  projectFilter?.projectStatus ||
                  projectFilter?.priority) && (
                  <div className="absolute w-5 h-5 flex justify-center items-center rounded-full bg-green-500 -right-4 -top-4 text-xs font-semibold text-custom-white">
                    {
                      Object.entries(projectFilter).filter(
                        ([key, value]) => value !== ""
                      ).length
                    }
                  </div>
                )}
                <MdOutlineFilterAlt size={20} />
                <span>Filters</span>
              </div>
            }
            onClick={() =>
              setOpenFilterDrawer((openFilterDrawer) => !openFilterDrawer)
            }
            buttonType="submit"
            classname="bg-custom-white text-slate-400 p-2 text-sm font-semibold rounded-md border"
          />
          {user?.roleId !== Roles?.employee && (
            <CustomButton
              title={
                <div className="flex justify-center items-center gap-2 ">
                  <FaPlusCircle />
                  <span>Add Project</span>
                </div>
              }
              buttonType="button"
              onClick={() => navigate("/project/create")}
              classname="bg-gradient-custom text-custom-white font-semibold text-sm px-5 py-2 rounded-md"
            />
          )}
        </div>
        {openFilterDrawer && (
          <CustomDrawer
            ref={drawerRef}
            open={openFilterDrawer}
            setOpen={setOpenFilterDrawer}
            filterName="Project Filter"
          >
            {user?.roleId !== Roles?.employee && (
              <div className="my-4">
                <FilterSelect
                  name="department"
                  label="Department"
                  placeholder="Select a department"
                  options={departmentOptions}
                  defaultValue={projectFilter?.department}
                  onChange={(selected) => {
                    dispatch(
                      setProjectFilter({
                        department: selected?.value,
                      })
                    );
                  }}
                />
              </div>
            )}
            {user?.roleId !== Roles?.employee && (
              <div className="my-4">
                <FilterSelect
                  name="project_manager"
                  label="Project Manager"
                  placeholder="Select a proejct manager"
                  options={projectManagerOptions}
                  defaultValue={projectFilter?.project_manager}
                  onChange={(selected) => {
                    dispatch(
                      setProjectFilter({
                        project_manager: selected?.value,
                      })
                    );
                  }}
                />
              </div>
            )}
            {user?.roleId !== Roles?.employee && (
              <div className="my-4">
                <FilterSelect
                  name="project_member"
                  label="Member"
                  placeholder="Select a proejct member"
                  options={employeeOptions}
                  defaultValue={projectFilter?.project_member}
                  onChange={(selected) => {
                    dispatch(
                      setProjectFilter({
                        project_member: selected?.value,
                      })
                    );
                  }}
                />
              </div>
            )}

            <div className="my-4">
              <FilterSelect
                name="status"
                label="Status"
                placeholder="Select a status"
                options={filterStatusOptions}
                defaultValue={projectFilter?.projectStatus}
                onChange={(selected) =>
                  dispatch(
                    setProjectFilter({
                      projectStatus: selected?.value,
                    })
                  )
                }
              />
            </div>
            <div className="my-4">
              <FilterSelect
                name="priority"
                label="Priority"
                placeholder="Select a Priority"
                options={filterPriorityOptions}
                defaultValue={projectFilter?.priority}
                onChange={(selected) =>
                  dispatch(
                    setProjectFilter({
                      priority: selected?.value,
                    })
                  )
                }
              />
            </div>
            <div className="w-full absolute bottom-0 right-0 p-4">
              <div className="w-full h-[1px] bg-slate-200 my-2" />
              <div className="flex items-center justify-between ">
                <CustomButton
                  title={"Clear"}
                  onClick={() => {
                    dispatch(setProjectFilter(null));
                    fetchProjects("", "", "", "", "");
                    setOpenFilterDrawer(false);
                  }}
                  buttonType="submit"
                  classname={"border px-5 py-1 rounded-md"}
                />
                <CustomButton
                  title={"Apply"}
                  onClick={() => {
                    filterProjectApiHandler();
                  }}
                  buttonType="submit"
                  classname={
                    "border px-5 py-1 rounded-md bg-custom-status-completed text-custom-white"
                  }
                />
              </div>
            </div>
          </CustomDrawer>
        )}
      </section>
      {projectLoading ? (
        <div className="w-full grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-5">
          {[1, 2, 3, 4, 5, 6]?.map((index) => (
            <div
              key={index}
              className="w-full border border-[#E8F0F6] bg-custom-white p-4 rounded-lg shadow-[2px_3px_10px_6px_#257CBB0F] space-y-3 cursor-pointer flex flex-col"
            >
              <div className="flex justify-between items-center space-x-2">
                <div className="w-40">
                  <Skeleton width={"70"} />
                </div>
                <div className="w-[15px] h-[15px]">
                  <Skeleton circle width={"100%"} height="100%" />
                </div>
              </div>
              <div>
                <Skeleton count={3} />
              </div>
              <div className="flex space-x-2">
                <div className="w-[25px] h-[25px]">
                  <Skeleton circle width={"100%"} height="100%" />
                </div>
                <div className="w-[25px] h-[25px]">
                  <Skeleton circle width={"100%"} height="100%" />
                </div>
                <div className="w-[25px] h-[25px]">
                  <Skeleton circle width={"100%"} height="100%" />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : tableData && tableData?.length > 0 ? (
        <section className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-5">
          {tableData?.map((project) => (
            <ProjectCard
              key={project?._id}
              _id={project?._id}
              project_name={project?.project_name}
              project_manager={project?.project_manager}
              department={project?.department}
              project_id={project?.project_id}
              project_description={project?.project_description}
              start_date={project?.project_start_date}
              end_date={project?.project_deadline}
              assignees={project?.members}
              status={project?.projectStatus}
              priority={project?.priority}
              progress={project?.project_progress}
              total_task={project?.total_task}
              completed_task={project?.completed_task}
              onClick={() => {
                dispatch(setActiveTabInProject("overview"));
                navigate("/project/view", { state: { id: project?._id } });
              }}
              setIsEditMode={setIsEditMode}
              fetchAllProjects={fetchProjects}
            />
          ))}
        </section>
      ) : (
        <p className="p-2 font-semibold text-sm">
          <NoDataFound />
        </p>
      )}
      <section className="flex sm:flex-row flex-col justify-between items-center text-slate-400 text-sm">
        <PaginationText
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          dataCount={projectCount}
          dataLength={projects?.length}
        />

        <Pagination
          handlePageClick={handlePageClick}
          pageRangeDisplayed={itemsPerPage}
          pageCount={pageCount}
          name={"pageSize"}
          onChange={(e) => setItemsPerPage(e?.target?.value)}
        />
      </section>
    </div>
  );
};

export default Project;
