import * as yup from "yup";

const nameValidation = yup.string().required("Name is required *").trim(),
  emailValidation = yup
    .string()
    .nullable()
    .email("Invalid email format")
    .required("Email is required *")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,6}$/i, "Invalid email format")
    .max(40, " Max 40")
    .trim(),
  mobileValidation = yup
    .string()
    .required("Mobile Number is required *")
    .trim(),
  departmentValidation = yup
    .string()
    .max(30, "Department must be less than 30 characters")
    .required("Department is required *")
    .trim(),
  roleValidation = yup.string().required("Role is required *").trim(),
  designationValidation = yup
    .string()
    .max(30, "Designation must be less than 30 characters")
    .required("designation is required *")
    .trim(),
  locationValidation = yup.string().required("Location is required *").trim(),
  workValidation = yup.string().required("Work Mode is required *").trim();

const CommonValidation = {
  nameValidation,
  emailValidation,
  mobileValidation,
  departmentValidation,
  roleValidation,
  designationValidation,
  locationValidation,
  workValidation,
};

export default CommonValidation;
