import React, { forwardRef } from "react";

const CustomDrawer = forwardRef(
  ({ open, setOpen, side = "right", filterName = "", children }, ref) => {
    return (
      <div
        id={`dialog-${side}`}
        className={`absolute z-50 w-full md:w-[40%] h-full transition-transform ease-linear delay-300 duration-500 ${
          open ? "opacity-100" : "opacity-0"
        }`}
        aria-labelledby="slide-over"
        role="dialog"
        aria-modal="true"
      >
        <div className={`${open ? "fixed inset-0 overflow-hidden" : ""}`}>
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={`pointer-events-none fixed w-[70%] md:w-[40%] lg:w-[30%] xl:w-[20%] max-w-full inset-y-0 right-0`}
            >
              <div
                className={`pointer-events-auto relative w-full h-full transform transition-transform ease-in-out duration-500 ${
                  open ? "translate-x-0" : "translate-x-full"
                }`}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                <div
                  ref={ref} // This will now receive the forwarded ref
                  className="flex flex-col h-full overflow-x-hidden overflow-y-scroll w-full p-3 shadow-xl bg-white rounded-lg"
                >
                  <div className="flex justify-between items-center mb-4">
                    <span className="text-lg font-semibold">{filterName}</span>
                    <div
                      onClick={() => setOpen(false)}
                      className="w-7 h-7 rounded-full bg-[#E7EFF5] text-custom-blue flex justify-center items-center cursor-pointer"
                    >
                      X
                    </div>
                  </div>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default CustomDrawer;
