import React from "react";
import CommonTooltip from "./CommonTooltip";
import { PriorityIcon } from "../project/Heading";

const CustomPriorityButton = ({ priority = "" }) => {
  return (
    <CommonTooltip tooltip={"Priority"}>
      <div
        className={`flex items-center gap-2 ${
          priority === "High"
            ? "bg-[#FFE9E9] text-red-500"
            : priority === "Medium"
            ? "bg-[#F1F3FD] text-custom-blue"
            : priority === "Low"
            ? "bg-[#F0EDE9] text-[#78736C]"
            : ""
        } font-medium text-sm p-1.5 rounded-md`}
      >
        <PriorityIcon value={priority} />
        <span>{priority}</span>
      </div>
    </CommonTooltip>
  );
};

export default CustomPriorityButton;
