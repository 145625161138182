import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiConnector } from "../../networking/ApiConnector";
import {
  milestoneEndPoints,
  projectEndPoints,
} from "../../networking/Endpoints";

// Define an async thunk
export const getAllProject = createAsyncThunk(
  "getAllProject",
  async ({ token, queryParams }) => {
    const response = await apiConnector(
      "GET",
      projectEndPoints?.PROJECT_API,
      null,
      {
        Authorization: `Bearer ${token}`,
      },
      queryParams
    );
    return response?.data?.data;
  }
);

export const getAllProjectMilestone = createAsyncThunk(
  "getAllProjectMilestone",
  async ({ token, currentPage, itemsPerPage, projectId }) => {
    const queryParams = {};

    if (currentPage && itemsPerPage) {
      queryParams.page_number = currentPage;
      queryParams.page_size = itemsPerPage;
    }
    const response = await apiConnector(
      "GET",
      `${milestoneEndPoints?.MILESTONE_API}/${projectId}`,
      null,
      {
        Authorization: `Bearer ${token}`,
      },
      queryParams
    );
    return response.data;
  }
);

// Create a slice
const projectSlice = createSlice({
  name: "project",
  initialState: {
    projectData: null,
    projectMilestoneData: null,
    projectLoading: false,
    projectMilestoneLoading: false,
    projectError: null,
    projectMilestoneError: null,
    activeTabInProject: "overview",
  },
  reducers: {
    setActiveTabInProject(state, value) {
      state.activeTabInProject = value.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProject.pending, (state) => {
        state.projectLoading = true;
        state.projectData = null;
        state.projectError = null;
      })
      .addCase(getAllProject.fulfilled, (state, action) => {
        state.projectData = action.payload;
        state.projectLoading = false;
        state.projectError = null;
      })
      .addCase(getAllProject.rejected, (state, action) => {
        state.projectError = action.error.message;
        state.projectLoading = false;
        state.projectData = null;
      })
      .addCase(getAllProjectMilestone.pending, (state) => {
        state.projectMilestoneLoading = true;
        state.projectMilestoneData = null;
        state.projectMilestoneError = null;
      })
      .addCase(getAllProjectMilestone.fulfilled, (state, action) => {
        state.projectMilestoneData = action.payload;
        state.projectMilestoneLoading = false;
        state.projectMilestoneError = null;
      })
      .addCase(getAllProjectMilestone.rejected, (state, action) => {
        state.projectMilestoneError = action.error.message;
        state.projectMilestoneLoading = false;
        state.projectMilestoneData = null;
      });
  },
});

export const { setActiveTabInProject } = projectSlice.actions;
export default projectSlice.reducer;
