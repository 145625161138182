import React from "react";
import ProjectJason from "../../dummyJason/ProductJason";

const CompanyCard = ({ item }) => {
  return (
    <div
      onClick={() => {
        window.open(item?.websiteUrl, "_blank");
      }}
      className="p-4 cursor-pointer border border-[#E3F3FF] hover:bg-gray-100 rounded-md space-y-2 shadow-[2px_3px_10px_6px_#257CBB0F]"
    >
      <div className="flex justify-start items-center gap-4">
        <img
          src={item?.image ? item?.image : ""}
          alt="Logo"
          className="w-14 md:w-14 h-14 md:h-14 rounded-full object-contain"
        />
        <div>
          <span className="block  text-black font-bold text-base">
            {item?.name ? item?.name : "--"}
          </span>
        </div>
      </div>
      <div className="w-full h-[1px] border border-dashed" />
      <div className="w-full space-y-2 text-sm">
        <span className="block text-xs text-slate-400 line-clamp-4 h-44">
          {item?.description ? item?.description : "--"}
        </span>
      </div>
    </div>
  );
};
const Products = () => {
  return (
    <div className="bg-custom-white p-4 rounded-md ">
      <h2 className="pb-4 font-semibold text-lg">SinghSoft Product's</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-5  ">
        {ProjectJason.map((item) => (
          <CompanyCard item={item} />
        ))}
      </div>
    </div>
  );
};

export default Products;
