import React from 'react'

function Needssection() {
    return (
        <div className='container mx-auto 2xl:py-[120px] xl:py-[120px] lg:py-[120px] md:py-[60px] py-[40px] xl:px-0 lg:px-0 px-[20px] bg-white'>
            <div className='text-center'>
                <span className='text-center relative'>
                    <span className='2xl:text-[40px] xl:text-[40px] lg:text-[40px] md:text-[36px] text-[32px] font-semibold text-center '>
                        An app tailored to your needs.
                    </span>
                    <span className='absolute right-0'>
                        <img src='../assets/needs-vector.png'></img>
                    </span>
                </span>
            </div>
            <div className='grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mt-[40px] '>
                <div className='bg-[#FAFAFA] rounded-[16px] p-[20px] border border-[#ECECEC] max-w-[365px] mx-auto'>
                    <div className='bg-white rounded-[16px] 2xl:min-h-[250px] xl:min-h-[250px] lg:min-h-[250px] md:min-h-[250px] min-h-[150px] flex items-center justify-center'>
                        <div className='2xl:w-[150px] xl:w-[150px] lg:w-[150px] md:w-[150px] w-[100px] 2xl:h-[150px] xl:h-[150px] lg:h-[150px] md:h-[150px] h-[100px]'>
                            <img src='../assets/book.png'></img>
                        </div>
                    </div>
                    <p className='text-[#0F0F0F] text-[18px] font-semibold mt-[18px]'>
                        Stay Organised
                    </p>
                    <p className='text-[#363636] 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] text-[14px] font-normal 2xl:mt-[12px] xl:mt-[12px] lg:mt-[12px] md:mt-[12px] mt-[5px]'>
                        Resource calendar and Reports give you the much needed visibility your team’s current, past and future.
                    </p>
                </div>
                <div className='bg-[#FAFAFA] rounded-[16px] p-[20px] border border-[#ECECEC] max-w-[365px] mx-auto'>
                    <div className='bg-white rounded-[16px] 2xl:min-h-[250px] xl:min-h-[250px] lg:min-h-[250px] md:min-h-[250px] min-h-[150px] flex items-center justify-center'>
                        <div className='2xl:w-[150px] xl:w-[150px] lg:w-[150px] md:w-[150px] w-[100px] 2xl:h-[150px] xl:h-[150px] lg:h-[150px] md:h-[150px] h-[100px]'>
                            <img src='../assets/to-do-list 1.png'></img>
                        </div>
                    </div>
                    <p className='text-[#0F0F0F] text-[18px] font-semibold mt-[18px]'>
                        Manage Your Task
                    </p>
                    <p className='text-[#363636] 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] text-[14px] font-normal 2xl:mt-[12px] xl:mt-[12px] lg:mt-[12px] md:mt-[12px] mt-[5px]'>
                        Resource calendar and Reports give you the much needed visibility your team’s current, past and future
                    </p>
                </div>
                <div className='bg-[#FAFAFA] rounded-[16px] p-[20px] border border-[#ECECEC] max-w-[365px] mx-auto'>
                    <div className='bg-white rounded-[16px] 2xl:min-h-[250px] xl:min-h-[250px] lg:min-h-[250px] md:min-h-[250px] min-h-[150px] flex items-center justify-center'>
                        <div className='2xl:w-[150px] xl:w-[150px] lg:w-[150px] md:w-[150px] w-[100px] 2xl:h-[150px] xl:h-[150px] lg:h-[150px] md:h-[150px] h-[100px]'>
                            <img src='../assets/virtual-assistants 1.png'></img>
                        </div>
                    </div>
                    <p className='text-[#0F0F0F] text-[18px] font-semibold mt-[18px]'>
                        Centre Communication
                    </p>
                    <p className='text-[#363636] 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] text-[14px] font-normal 2xl:mt-[12px] xl:mt-[12px] lg:mt-[12px] md:mt-[12px] mt-[5px]'>
                        Resource calendar and Reports give you the much needed visibility your team’s current, past and future
                    </p>
                </div>
                <div className='bg-[#FAFAFA] rounded-[16px] p-[20px] border border-[#ECECEC] max-w-[365px] mx-auto'>
                    <div className='bg-white rounded-[16px] 2xl:min-h-[250px] xl:min-h-[250px] lg:min-h-[250px] md:min-h-[250px] min-h-[150px] flex items-center justify-center'>
                        <div className='2xl:w-[150px] xl:w-[150px] lg:w-[150px] md:w-[150px] w-[100px] 2xl:h-[150px] xl:h-[150px] lg:h-[150px] md:h-[150px] h-[100px]'>
                            <img src='../assets/schedule 1.png'></img>
                        </div>
                    </div>
                    <p className='text-[#0F0F0F] text-[18px] font-semibold mt-[18px]'>
                        Meet Your Deadline
                    </p>
                    <p className='text-[#363636] 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] text-[14px] font-normal 2xl:mt-[12px] xl:mt-[12px] lg:mt-[12px] md:mt-[12px] mt-[5px]'>
                        Resource calendar and Reports give you the much needed visibility your team’s current, past and future
                    </p>
                </div>

            </div>
        </div>
    )
}

export default Needssection