import React, { useEffect, useState } from "react";
import { CustomButton } from "../common";
import { Heading } from "../project";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dateFormatter } from "../../helper/formatDate";
import Skeleton from "react-loading-skeleton";
import { fetchCompanyDetails } from "../../redux/slice/companySlice";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const CompanyView = () => {
  const [companyDetails, setCompanyDetails] = useState(null),
    { token } = useSelector((state) => state.auth),
    { companyLoading } = useSelector((state) => state.company),
    location = useLocation(),
    navigate = useNavigate(),
    dispatch = useDispatch();
  const companyId = location?.state?.company_id;
  const getCompanyDetails = async () => {
    const response = await dispatch(
      fetchCompanyDetails({ token, companyId })
    ).unwrap();

    if (response?.data) {
      setCompanyDetails(response?.data?.company_details);
    }
  };
  useEffect(() => {
    getCompanyDetails();
  }, [companyId]);
  return (
    <div className="w-full h-full bg-custom-white rounded-md p-4">
      <div className="relative font-semibold">
        <span className="text-xl">Company Details</span>
        <CustomButton
          title={"Edit Company Details"}
          buttonType={"button"}
          onClick={() => {
            navigate("/company/create", {
              state: { companyId: companyDetails?._id },
            });
          }}
          classname="absolute right-0 top-0 underline text-[#2E6790] px-5 py-1.5 rounded-md"
        />
      </div>

      {companyLoading ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-4 gap-y-10 my-5">
          {/* Skeleton loaders for company details */}
          {[...Array(9)].map((_, idx) => (
            <div>
              <Skeleton key={idx} width={"50%"} height={20} />
              <Skeleton key={idx} width={"100%"} height={20} />
            </div>
          ))}
        </div>
      ) : (
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-4 gap-y-10 my-5 border-b pb-4">
          <Heading label="Company Name" value={companyDetails?.company_name} />
          <Heading
            label="Company Email"
            value={companyDetails?.company_email}
          />
          <Heading
            label="Company Contact"
            value={
              companyDetails?.company_country_code +
              " " +
              companyDetails?.company_contact_no
            }
          />
          <Heading
            label="Department Limit"
            value={companyDetails?.department_limit ?? "--"}
          />
          <Heading
            label="Designation Limit"
            value={companyDetails?.designation_limit ?? "--"}
          />
          <Heading
            label="Office Location Limit"
            value={companyDetails?.office_location_limit ?? "--"}
          />

          <Heading
            label="Company Address"
            value={companyDetails?.company_address}
          />
          <Heading
            label="Created At"
            value={
              companyDetails?.createdAt
                ? dateFormatter(companyDetails?.createdAt)
                : "--"
            }
          />
          <Heading
            label="Last Updated"
            value={
              companyDetails?.updatedAt
                ? dateFormatter(companyDetails?.updatedAt)
                : "--"
            }
          />
          <div>
            <h3 className="text-[#757575]">Status</h3>
            <div className="flex items-center text-sm text-start gap-2">
              <div
                className={`w-3 h-3 rounded-full ${
                  companyDetails?.isActive ? "bg-green-500" : "bg-red-500"
                }`}
              />
              <span>{companyDetails?.isActive ? "Active" : "Inactive"}</span>
            </div>
          </div>
        </div>
      )}

      <span className="text-xl">Admin Details</span>

      {companyLoading ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-4 gap-y-10 my-5">
          {/* Skeleton loaders for admin details */}
          {[...Array(3)].map((_, idx) => (
            <div>
              <Skeleton key={idx} width={"50%"} height={20} />
              <Skeleton key={idx} width={"100%"} height={20} />
            </div>
          ))}
        </div>
      ) : (
        companyDetails?.admin?.map((admin, index) => (
          <div
            key={index}
            className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-4 gap-y-10 my-5"
          >
            <Heading
              label="Username"
              value={
                <div className="flex justify-start items-center gap-3">
                  <img
                    src={
                      admin?.avatar
                        ? `${BASE_URL}/${admin?.avatar}`
                        : `https://api.dicebear.com/5.x/initials/svg?seed=${admin?.user_name}`
                    }
                    alt={admin?.user_name[0]}
                    className="w-7 h-7 rounded-full bg-slate-300 p-0.5"
                  />
                  <span>{admin?.user_name}</span>
                </div>
              }
            />
            <Heading label="Email" value={admin?.actual_email} />
            <Heading
              label="Mobile Number"
              value={admin?.country_code + " " + admin?.actual_mobile_number}
            />
          </div>
        ))
      )}
    </div>
  );
};

export default CompanyView;
