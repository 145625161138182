import React from "react";

function Business() {
  return (
    <div className="bg-[#F1F9FF] container mx-auto rounded-[16px] 2xl:p-[50px] xl:p-[50px] lg:p-[50px] p-[20px]">
      <div className="xl:text-start lg:text-start text-center">
        <span className="text-center relative">
          <span className="2xl:text-[40px] xl:text-[40px] lg:text-[40px] md:text-[36px] text-[32px] font-semibold text-center ">
            Take control of your business
          </span>
          <span className="absolute right-0">
            <img src="../assets/business-img.png"></img>
          </span>
        </span>
      </div>
      <div className="flex 2xl:flex-row xl:flex-row lg:flex-row flex-col items-center justify-between 2xl:mt-[70px] xl:mt-[70px] lg:mt-[70px] mt-[30px] 2xl:gap-0 xl:gap-10 lg:gap-10">
        <div className="bg-[#DFF1FE] p-[20px] 2xl:max-w-[730px] xl:max-w-[730px] max-w-[100%] rounded-[5px]">
          <img src="../assets/ProjectOverview.svg"></img>
        </div>
        <div className="2xl:max-w-[570px] xl:max-w-[570px] lg:max-w-[570px] max-w-[100%] xl:mt-0 lg:mt-0 mt-[20px]">
          <p className="text-[#0F0F0F] text-[26px] font-semibold">
            Project Dashboard
          </p>
          <p className="2xl:text-[17px] xl:text-[17px] lg:text-[17px] md:text-[16px] text-[14px] mt-[25px] font-normal">
            A Project Manager Dashboard is a powerful tool designed to provide a
            comprehensive overview of a project's progress, resources, and key
            performance indicators
          </p>
          <ul className="mt-[32px] ml-[18px] 2xl:[column-count:1] xl:[column-count:1] lg:[column-count:1] md:[column-count:2] [column-count:1]">
            <li className="list-disc mb-[26px]">
              Resource calendar and Reports give you the much needed .
            </li>
            <li className="list-disc mb-[26px]">
              Resource calendar and Reports give you the much needed .
            </li>
            <li className="list-disc mb-[26px]">
              Resource calendar and Reports give you the much needed .
            </li>
            <li className="list-disc mb-[26px]">
              Resource calendar and Reports give you the much needed .
            </li>
          </ul>
        </div>
      </div>
      <div className="flex 2xl:flex-row xl:flex-row lg:flex-row flex-col items-center justify-between 2xl:mt-[100px] xl:mt-[100px] lg:mt-[100px] md:mt-[50px] mt-[30px]">
        <div className="2xl:max-w-[570px]xl:max-w-[570px] lg:max-w-[570px] max-w-[100%] xl:order-1 lg:order-1 order-2 xl:mt-0 lg:mt-0 mt-[20px]">
          <p className="text-[#0F0F0F] text-[26px] font-semibold">
            Task Dashboard
          </p>
          <p className="text-[17px] mt-[25px] font-normal">
            A Project Manager Dashboard is a powerful tool designed to provide a
            comprehensive overview of a project's progress, resources, and key
            performance indicators
          </p>
          <ul className="mt-[32px] ml-[18px] 2xl:[column-count:1] xl:[column-count:1] lg:[column-count:1] md:[column-count:2] [column-count:1]">
            <li className="list-disc mb-[26px]">
              Resource calendar and Reports give you the much needed .
            </li>
            <li className="list-disc mb-[26px]">
              Resource calendar and Reports give you the much needed .
            </li>
            <li className="list-disc mb-[26px]">
              Resource calendar and Reports give you the much needed .
            </li>
            <li className="list-disc mb-[26px]">
              Resource calendar and Reports give you the much needed .
            </li>
          </ul>
        </div>
        <div className="bg-[#DFF1FE] p-[20px] max-w-[730px] rounded-[5px] xl:order-2 lg:order-2 order-1	">
          <img src="../assets/task.svg"></img>
        </div>
      </div>
    </div>
  );
}

export default Business;
